import { all, takeEvery, put, fork } from 'redux-saga/effects'
import actions from '../actions/authAction'
import AuthHelpers from '../../helpers/authHelper'
import env from "../../environment"
const axios = require('axios').default
const queryString = require('query-string')

export function* login() {
	yield takeEvery(actions.LOGIN, function*(data) {
		const {payload} = data
		const {phoneNumber, password} = payload
		var formData = new FormData()
		var fetchApi = null
		formData.append('grant_type', 'password');
    	formData.append('client_id', env.apiClientId);
			formData.append('client_secret', env.apiSecret);
    	formData.append('username', phoneNumber);
    	formData.append('password', password);
    	formData.append('scope', "");

    	fetchApi = yield axios({
    		crossdomain: true,
    	    method: 'post',
    	    url: "/api/v1/oauth/token",
    	    data: formData,
    	    headers: {'Content-Type': 'multipart/form-data' }
	    }).then(response => {
	    	return response;
	    }).catch(function (error) {})

    	var responsdata = (fetchApi) ? fetchApi.data : null;

	    if(responsdata){
	    	yield put({
	    	  type: actions.LOGIN_SUCCESS,
	    	  data: {access_token : responsdata.access_token, expires_in : responsdata.expires_in, id: null, refresh_token : responsdata.refresh_token},
	    	});
	    } else {
	    	yield put({
	    	  type: actions.LOGIN_FAILED
	    	});
	    }
	})

}

export function* checkAuthorization() {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function*(data) {
		let token = yield AuthHelpers.token();

	   if(typeof token != "undefined" && token) {
	   	yield put({
	   	  type: actions.LOGIN_SUCCESS,
	   	});
	   }
	})

}
export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
  	if(typeof payload.data != "undefined") {
      yield AuthHelpers.setToken(payload.data);
  	}
  });
}
export default function* rootSaga() {
	yield all([
		fork(login),
		fork(loginSuccess),
		fork(checkAuthorization)
	]);
}