import { all, take, takeEvery, put, fork } from "redux-saga/effects";
import actions from "../actions/cartAction";
import ApiCaller from "../../helpers/ApiCaller";
import AuthHelpers from '../../helpers/authHelper';
import authAction from '../actions/authAction';
import { store } from "../store"
const axios = require('axios').default
const queryString = require('query-string')



export function* getCart(){
    yield takeEvery(actions.GET_CART, function*(data){
        var fetchApi = yield ApiCaller({
            crossdomain : true,
            method      : "GET",
            url         : "/v1/products/cartitems"
        }).then((response) => {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const {data : responseData = {} } = response
            return response
        })
        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload, session_id, cart_total, warning = null } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_CART_COMPLETE,
                        payload : payload, 
                        cartTotal: cart_total,
                        warning: warning,
                        sessionID : session_id
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}

export function* deleteItem()
{
    yield takeEvery(actions.DELETE_ITEM, function*(dataParams){
       
        var fetchApi = yield ApiCaller({
            crossdomain : true,
            method      : "DELETE",
            url         : "/v1/products/deletefromcart",
            data: dataParams
        }).then((response) => {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const {data : responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload, session_id, cart_total, warning = null } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_CART_COMPLETE,
                        payload : payload, 
                        cartTotal: cart_total,
                        sessionID : session_id,
                        warning: warning
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
        
    })
}

export function* udpateItem()
{
    yield takeEvery(actions.UPDATE_ITEM, function*(dataParams){
       
        var fetchApi = yield ApiCaller({
            crossdomain : true,
            method      : "PUT",
            url         : "/v1/products/updatecart",
            data: dataParams

            // apiUrl:"/api/v1/products/updatecart",
            // method:"PUT",
            // data:dataParams,
        }).then((response) => {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const {data : responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload, session_id, cart_total, warning = null } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_CART_COMPLETE,
                        payload : payload, 
                        cartTotal: cart_total,
                        sessionID : session_id,
                        warning : warning
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
        
    })
}
export function* getSearchProduct(action){
    console.log(action)

    yield take(actions.GET_SEARCH_PRODUCT, function*(data){
        var fetchApi = yield ApiCaller({
            crossdomain:true,
            method: 'GET',
            url: '/v1/products',
        }).then((response)=> {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const { data: responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_SEARCH_PRODUCT_COMPLETE,
                        payload : payload
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getCart),
        fork(getSearchProduct),
        fork(deleteItem),
        fork(udpateItem)
    ]);
}