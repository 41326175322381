
import { store } from '../redux/store'
import authAction from '../redux/actions/authAction'
import CustomMessage from './CustomMessage'
import AuthHelpers from './authHelper'
const axios = require('axios').default
const { alertType, buildAlert, alertModel, createErrorMessage} = CustomMessage
class CallApi{
	constructor(config = {
		apiUrl : "",
		path : "",
		method : "GET" ,
		crossdomain : true,
		data : {},
		params : {},
		headers : [],
		logout_unauthorized : false,
		alert : false,
		defaultResponse : true,
	}){

		this.apiUrl = config.apiUrl;
		this.path = config.path;
		this.crossdomain = config.crossdomain;
		this.method = config.method;
		this.data = config.data;
		this.headers = (config.headers) ? 
						config.headers : 
						{ 'Accept': 'application/json', 'Content-Type': "application/json"}

		this.params = config.params;
		this.logout_unauthorized = config.logout_unauthorized;
		this.responseData = {};
		this.responseStatus = 0;
		this.responseMessage = {};
		this.errorCode = null;
		this.errorDesc = "";
		this.alert = config.alert;
		this.defaultResponse = config.defaultResponse;
		
	}

	async post() {

		var responseStatus = this.responseStatus
		var responseMessage = this.responseMessage

		try{
			var token = await AuthHelpers.token()
	      	if (!token) {
	      		return false
	      	}
	      	if(!this.apiUrl){
	      		new buildAlert({
					alertModel : alertModel.MODAL,
					type : alertType.ERROR,
					code : 'AP001',
					message : "Invalid api url",
					additionalInfo : 'Please contact web master for more information.'
				});
	      		return false
	      	}
	      	var options = {
	    		crossdomain: this.crossdomain,
	    	    method: 'GET',
	    	    url: this.apiUrl,
	    	    headers: this.headers
		    };
		    options.method = this.method;
			this.headers['Authorization'] = 'Bearer ' + token;

		    if ( this.data ) {
		    	options.data = this.data;
		    }

		    if( this.params ) {
		    	options.params = this.params;
		    }

			await axios(options).then(response => {
		    	responseStatus = 200
		    	responseMessage = "OK"
		    	this.responseData = response
		    }).catch(function (error) {
		    	responseStatus = error.response.status || 500
		    	responseMessage = error.response.data.message || error.response.statusText
		    })

		    this.responseStatus = responseStatus
		    this.responseMessage = responseMessage

		}catch(e){
			this.responseStatus = 500
		    this.responseMessage = e.message
		}
		return this.networkHandler();
	}

	error(){

	}

	info(){

	}

	networkHandler(){
		return new Promise((resolve, reject) => {
			try {

				if(this.responseStatus != 200 && this.responseStatus != 401){

					var responseStatus = this.responseStatus;
					var responseMessage = this.responseMessage;
					// new buildAlert({
					// 	alertModel : alertModel.MODAL,
					// 	type : alertType.ERROR,
					// 	code : responseStatus,
					// 	message : responseMessage,
					// 	additionalInfo : "Unable to load data from server. Please contact web master"
					// });
					new createErrorMessage()
					reject(this.responseStatus);
				}else if(this.responseStatus == 401){
					if(this.logout_unauthorized){

						AuthHelpers.removeToken();
						store.dispatch(authAction.noAuth());

					}else{
						new buildAlert({
							alertModel : alertModel.MODAL,
							type : alertType.ERROR,
							code : '401',
							message : "Unauthorized",
							additionalInfo : "Unable to load data from server. Please contact web master"
						});
					}
					reject(false);
				}else {
					resolve(this.responsJson());
				}
			} catch (e) {
				reject(false);
			}
		})
	}

	responsJson(){
		return new Promise((resolve, reject) => {
			try {

				this.errorCode = (this.responseData) ? this.responseData.data.error_code : 0;
				this.errorDesc = (this.responseData) ? this.responseData.data.error_message : 0; 
				if(this.errorCode === "200"){
					if(this.alert){
						new buildAlert({
		        			alertModel : alertModel.NOTIFICATION,
		        			type : alertType.SUCCESS,
		        			code : "",
		        			message : this.alert.message,
		        		});
					}
					resolve(this.responseData);
				}else{
					if(this.defaultResponse == false){
						resolve(this.responseData);
					}else{

						new buildAlert({
							alertModel : alertModel.MODAL,
							type : alertType.ERROR,
							code : this.errorCode,
							message : this.errorDesc,
							additionalInfo : "Unable to load data from server. Please contact web master"
						});
						reject(false);
						return false;
					}
				}

			} catch (e) {
				
				reject(false);
			}
		})
	}


}

export default {CallApi};