const actions = {
	HIDE_HEADER: "HIDE_HEADER",
	SHOW_HEADER: "SHOW_HEADER",
	SWITCH_HEADER: "SWITCH_HEADER",

	hideHeader: () => ({type: actions.HIDE_HEADER}),
	showHeader: () => ({type: actions.SHOW_HEADER}),
	switchHeader: (payload) => ({type: actions.SWITCH_HEADER, payload}),
}


export default actions