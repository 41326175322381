const actions = {
    GET_ALL_PRODUCT : 'GET_ALL_PRODUCT',
    GET_PRODUCT_COMPLETE : 'GET_PRODUCT_COMPLETE',
    GET_CATEGORIES : 'GET_CATEGORIES',
    GET_CATEGORIES_COMPLETE : 'GET_CATEGORIES_COMPLETE',
    GET_BEST_SELLER : 'GET_BEST_SELLER',
    GET_BEST_SELLER_COMPLETE : 'GET_BEST_SELLER_COMPLETE',
    GET_SEARCH : 'GET_SEARCH',
    GET_SEARCH_COMPLETE : 'GET_SEARCH_COMPLETE',
    getAllProduct: (params) => ({
        type: actions.GET_ALL_PRODUCT,
        params: params
    }),
    getCategories : () => ({
        type: actions.GET_CATEGORIES
    }),
    getBestSeller : (params) => ({
        type: actions.GET_BEST_SELLER,
        params: params
    }),
    getSearch : (params) => ({
        type: actions.GET_SEARCH,
        params: params
    })

   
}

export default actions