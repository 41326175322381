import React, {useState, useEffect} from "react"
import { Layout, Form, Input, Button, Space, Row, Col } from 'antd'
import { connect } from 'react-redux'

import {useHistory} from "react-router"
import  countDownAction from '../../redux/actions/countDownAction'
const { setCount } = countDownAction

const axios = require('axios').default
const { Header, Content, Footer } = Layout;

const ForgotPasswordPhoneNumber = (props) => {

	const [form] = Form.useForm()
	const history = useHistory()
	const [formTag, setFormTag] = useState(null)
	const [disableButton, setDisableButton] = useState(false)
	const [accountNotFound, setAccountNotFound] = useState(false)
	const [helpMessage, setHelpMessage] = useState({})
	var timer = null;

	const onPrevious = () => {
		history.push("/login")
	}
	const onFinish = async values => {
		setDisableButton(true)
	    var formData = new FormData()
		var fetchApi = null
    	formData.append('phone_number', values.phone_number)
    	fetchApi = await axios({
    		crossdomain: true,
    	    method: 'post',
    	    url: "/api/v1/auth/forgot-password",
    	    data: formData,
    	    headers: {'Content-Type': 'multipart/form-data' }
	    }).then(async (response) => {
	    	const {data} = response
	    	try {
	    		const {error_code, error_message, id = null, validIn= 0} = data
	    		if(["20A", "200"].indexOf(error_code) >= 0) {
	    			if(error_code == "200" && id){
	    				props.onSubmit({
	    					phoneNumber: values.phone_number, 
	    					formTag: "otp_form", 
	    					id: id,
	    					validIn: validIn})
	    				props.setCount(90)
	    			}
	    		} else{
	    			setHelpMessage({
						validateStatus: "error",
						help: "Nomor handphone tidak ditemukan"
					})
	    			setFormTag(null)
	    		}

	    	} catch(e) {
	    		setFormTag(null)
	    	}
	    	
	    }).catch(function (response) {
	        console.log(response);
	    });
		setDisableButton(false)
		
	};

	
	const onFinishFailed = errorInfo => {}

	useEffect(() => {
		form.setFieldsValue({
			phone_number : props.formData.phoneNumber,
		});
	},[])
	const clearError = () => setHelpMessage({})
	
	return(
		<>
		<div style={{ display: "flex", justifyContent: "center" }}>
              <label className="font-title text-center">Masukkan Phone number</label>
            </div>
		
  		<Form
  			scrollToFirstError={true}
  			autoComplete="off"
  			form={form}
  			size="large"
  			name="basic"
  			layout="vertical"
  			onFinish={onFinish}
  			onFinishFailed={onFinishFailed} 
  			
  			>

  			<Form.Item
  			    label="Nomor HP"
  			    name="phone_number"
  			    rules={[
  			    	{ required: true, message: 'Nomor HP Tidak boleh kosong!' },
  			    ]} 
      			{ ...helpMessage }>
				
				<Input
					onChange={clearError}
					 type="number"
					  autoFocus={true} 
					  className="input-default input-gray" 
					  placeholder="Masukkan nomor hp" />
  			 </Form.Item>

  			 
  			 <Form.Item>
		 		<Row  gutter={24}>
		 			
		 			<Col span={12}>
				 		<Button 
				 			onClick={onPrevious}
				 			block
					 		type="default" 
					 		size="large" 
					 		className="btn-app"
					 		htmlType="button">
							<i className="fas fa-caret-left fa-fw" /> Sebelumnya
						</Button>
					</Col>
		 			<Col span={12}>
		  			 	<Button 
		  			 		block
		  			 		loading={disableButton} 
		  			 		className="btn-app-primary btn-app" 
		  			 		style={{float: 'right'}} 
		  			 		type="primary" 
		  			 		size="large" 
		  			 		htmlType="submit"
		  			 		>
							Selanjutnya
							 <i className="fas fa-caret-right fa-fw" />
		  				</Button>
		 				
		 			</Col>
		 		</Row>
  			 	
			</Form.Item>
  		</Form>
  		</>
	)
}

// export default LoginPhoneNumber
export default connect(
	state => ({
		time: state.countDownReducer.time,
	}),
	{ setCount }
)(ForgotPasswordPhoneNumber);
