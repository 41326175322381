import asyncComponent from '../../helpers/AsyncFunc';
/*import ProductsForm from '../stock-manager/products/form';
import CategoriesForm from '../stock-manager/categories/form';
import SuppliersForm from '../stock-manager/suppliers/form';
import UnitsForm from '../stock-manager/units/form';
import WarehousesForm from '../stock-manager/warehouses/form';*/
const items = [{
		name : "change-password", 
		label : "Change Password",
		pathname: "/account/change-password",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../account/ChangePassword')),
		state : {},
	},
	{
		name : "change-password-token", 
		label : "Change Password",
		pathname: "/account/change-password/:reset_token",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../account/NewPassword')),
		state : {},
	},
	{
		name : "confirm-password-token", 
		label : "Comfirm Password",
		pathname: "/account/change-password/:reset_token/:pin",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../account/NewPasswordConfirm')),
		state : {},
	},
	{
		name : "transaction-history", 
		label : "Transaction History",
		pathname: "/account/transaction-history",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../transaction/TransactionHistory')),
		state : {},
	},
	{
		name : "address", 
		label : "Address",
		pathname: "/account/address",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../account/DeliveryAddress')),
		state : {},
	},
	// {
	// 	name : "withdraw-overview", 
	// 	label : "Withdraw Overview",
	// 	pathname: "/withdraw/overview/:transaction_id",
	// 	search: "",
	// 	hash: "",
	// 	icon : null,
	// 	forceRefresh : true,
	// 	component : asyncComponent(() => import('../withdraw/WithdrawOverview')),
	// 	state : {},
	// },{
	// 	name : "withdraw-result", 
	// 	label : "Withdraw Result",
	// 	pathname: "/withdraw/result/:transaction_no",
	// 	search: "",
	// 	hash: "",
	// 	icon : null,
	// 	forceRefresh : true,
	// 	component : asyncComponent(() => import('../withdraw/WithdrawResult')),
	// 	state : {},
	// },
	{
		name : "privacy-policy", 
		label : "Privacy Policy",
		pathname: "/privacy-policy",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../PrivacyPolicy')),
		state : {},
	},{
		name : "terms-of-service", 
		label : "Ketentuan Layanan",
		pathname: "/terms-of-service",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../Terms')),
		state : {},
	}
	,{
		name : "luckydraw", 
		label : "Luckydraw",
		pathname: "/luckydraw/:id",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import('../luckydraw/LuckydrawDetail')),
		state : {},
	}
];

export default items;