import { all } from 'redux-saga/effects'
import authSaga from "./sagas/authSaga"
import balanceSaga from "./sagas/balanceSaga"
import accountSaga from "./sagas/accountSaga"
import luckydrawSaga from "./sagas/luckydrawSaga"
import cartSaga from "./sagas/cartSaga"
import productSaga from "./sagas/productSaga"
export default function* rootSaga(getState) {
	yield all([
		authSaga(),
		balanceSaga(),
		accountSaga(),
		luckydrawSaga(),
		cartSaga(),
		productSaga()
	]);
}
