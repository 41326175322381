const actions = {
	GET_BALANCE: "GET_BALANCE",
	GET_BALANCE_COMPLETE: "GET_BALANCE_COMPLETE",
	WITHDRAW: "WITHDRAW",
	WITHDRAW_COMPELTE: "WITHDRAW_COMPLETE",
	getBalance: (payload) => ({
		type: actions.GET_BALANCE,
		payload
	}),
	withdraw: (payload) => ({
		type: actions.WITHDRAW,
		payload
	})
}

export default actions