import React from "react"

import {useHistory} from "react-router"
import { Form, Input, Button, Space } from 'antd';


const ForgotPasswordNewPin = (props) => {
	const [form] = Form.useForm();
	const history = useHistory();
	const onFinish = values => {
	    props.onSubmit(values.password)
	};
	
	const onFinishFailed = errorInfo => {

	};

	function onPrevious(){
		props.onPrevious()
	}
	return(
		<>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<label className="font-title text-center">Buat Pin Baru</label>
			</div>
			<Form size="large"
				autoComplete="off"
				scrollToFirstError={true}
				form={form}
				name="activation_form"
				layout="vertical"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed} >

				<Form.Item
					label="New PIN"
					name="password"
					rules={[
						{ required: true, message: 'Please enter PIN' },
						{ min: 6, message: 'Pin harus 6 angka' },
						({ getFieldValue }) => ({
								validator(_, value) {
									var pattern = /^\d+$/;
									if ( !pattern.test(value) && value.length == 6) {
										return Promise.reject(new Error('PIN harus berupa angka'));
									}
									return Promise.resolve();
								},
							}),
					]} >
					
					<Input 
						className="input-default input-gray text-center" 
						autoFocus={true} 
						maxLength={6}
						type="password" 
						placeholder="Enter new PIN"

						/>
				</Form.Item>
				<Form.Item
					label="Confirm PIN"
					name="confirm_password"
					rules={[
						{ required: true, message: 'Konfirmasi pin harus diisi' },
						{ min: 6, message: 'Pin harus 6 angka' },
						({ getFieldValue }) => ({
								validator(_, value) {
									var pattern = /^\d+$/;
									if ( !pattern.test(value) && value.length == 6) {
										return Promise.reject(new Error('PIN harus berupa angka'));
									}
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Pin tidak sesuai'));
								},
							}),
					]} >
					
					<Input 
						className="input-default input-gray text-center" 
						type="password" 
						maxLength={6}
						placeholder="Confirm new password"


						/>
				</Form.Item>
				<Form.Item >
				<Space  style={{float: 'right'}}>
					<Button 
						type="default" 
						size="large" 
						htmlType="button"
						onClick={onPrevious}>
							Sebelumnya
					</Button>
					
					<Button style={{float: 'right'}} type="primary" size="large" htmlType="submit">
						Selanjutnya
					</Button>
					</Space>
				</Form.Item>
			</Form>
		
		</>
		
	)
}

export default ForgotPasswordNewPin

