import React from 'react';
import { notification, Modal, message} from 'antd';
import AppConstant from "./AppConstant";
import ModalAlert from "@Components/ModalAlert";

import { FrownOutlined } from '@ant-design/icons';
const {alertType, alertModel} = AppConstant;
const template = (code, message, additionalInfo = null) => {
	return (
		<div>
			<p style={{margin : 0}} > {code} {(code) ? "-" : ""} {message}</p>
			{
				(additionalInfo) 
				? 
				<div>
					<hr style={{borderColor : "rgba(0,0,0,.2)", 
						borderType: "solid", 
						borderWidth : "1px", 
						borderTop : "0px"
					}}/>
					<p style={{margin : 2, fontSize : "11px"}}>{additionalInfo}
					</p>
				</div>  
				: 
				"" 
			}
		</div>
	);
}

const displayMessage = (type, code, message) => {
	 switch (type) {

	 	case alertType.ERROR :
			return notification.error({
			    message: `Request Error!!!`,
			    description: template(code, message),
			    icon: <FrownOutlined style={{ color: '#d32f2f' }} />,
			    placement :'topRight'
			})
		break;
		default : 
			return notification.info({
			    message: `Error`,
			    description: template(code, message),
			    placement :'topRight'
			})
		break;
	 }
}

class buildAlert{
	constructor(config = {
		alertModel : alertModel.NOTIFICATION , 
		type : alertType.INFO , 
		code : 200, 
		message : "",
		placement : "topRight",
		additionalInfo : null,
		onOk: null
	}) {
		switch (config.alertModel) {
			case alertModel.NOTIFICATION :
				return this.notification(config.type, config.code, config.message, config.placement, config.additionalInfo);
			break;
			case alertModel.MODAL :
				return this.modal(config.type, config.code, config.message, config.placement, config.additionalInfo, config.onOk);
			break;
			default :
				return this.notification(config.type, config.code, config.message, config.placement, config.additionalInfo);
			break;
		}
	}

	notification(type, code, message, placement, additionalInfo){
		switch (type) {
		 	case alertType.ERROR :
				return notification.error({
				    message: `Request Error!!!`,
				    description: template(code, message, additionalInfo),
				    icon: <FrownOutlined style={{ color: '#d32f2f' }} />,
				    placement : placement
				})
			break;
			default : 
				return notification.success({
				    message: `Info`,
				    duration : 2,
				    description: template(code, message, additionalInfo),
				   placement: placement
				})
			break;
		 }
	}

	modal(type, code, message, placement, additionalInfo, onOk){
		switch (type) {
		 	case alertType.ERROR :
		 		return Modal.error({
				    title: 'Request Error!!!',
				    content: (template(code, message, additionalInfo)),
				    onOk: onOk,
				  });
			break;
			default : 
				return Modal.info({
				    title: 'Request Error!!!',
				    content: (template(code, message, additionalInfo)),
				    onOk:onOk,
				});
			break;
		 }

	}

	alert(){

	}
}

class createErrorMessage{
	
	constructor() {
		return message.error({top: 120, content:"Ups. Terjadi kesalahan"})
	}
}
export default {buildAlert, displayMessage, alertType, alertModel, createErrorMessage};