import React, { useState, useEffect, useRef } from "react";
import { Layout, Modal, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoginPhoneNumber from "./LoginPhoneNumber";
import LoginActivation from "./LoginActivation";
import LoginActivationConfirmPin from "./LoginActivationConfirmPin";
import LoginPassword from "./LoginPassword";
import LoginOtp from "./LoginOtp";
import ForgotPasswordRequest from "./ForgotPasswordRequest";
import env from "../../environment";
import authAction from "../../redux/actions/authAction";
import countDownAction from "../../redux/actions/countDownAction";
import AppConstant from "../../app/constants/AppConstant";
const axios = require("axios").default;
const { Content } = Layout;

const { loginSuccess, login } = authAction;
const { setCount } = countDownAction;
const Login = (props) => {
  const [formTag, setFormTag] = useState(null);
  const [tmpPin, setTmpPin] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const phoneNumberRef = useRef();
  const [formData, setFormData] = useState({
    phoneNumber: null,
    requestId: null,
    password: null,
    otp: null,
    otpFormKey: "initial",
  });
  const [otpVisible, setOtpVisible] = useState(false);
  const [actVisible, setActVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [header, setHeader] = useState("");
  const history = useHistory();

  function onPageLoad() {
    if (props.authReducer.isFailed == true) {
      message.error(AppConstant.INVALID_PIN);
    }
  }
  useEffect(() => {
    if (props.authReducer.isFailed == true) {
      message.error(AppConstant.INVALID_PIN);
    }
  }, [props.authReducer]);

  function onSubmitPhoneNumber(props) {
    setFormData((prevProps, state) => ({
      ...prevProps,
      phoneNumber: props.phoneNumber,
      requestId: props.id,
      validIn: props.validIn,
    }));
    setFormTag(props.formTag);
  }

  const goToActivation = () => setFormTag("activation_form");
  const goToPhoneNumberForm = () => setFormTag(null);
  const requestToken = (password) =>
    props.login({ phoneNumber: formData.phoneNumber, password: password });

  async function setPin(e) {
    console.log(e);
    setTmpPin(e);
    setFormTag("confirm_activation_form");
  }

  async function firstTimeLogin(password) {
    var _formData = new FormData();
    var fetchApi = null;
    _formData.append("client_secret", env.apiSecret);
    _formData.append("phone_number", formData.phoneNumber);
    _formData.append("new_password", password);
    _formData.append("otp", formData.otp);
    _formData.append("id", formData.requestId);
    _formData.append("scope", "");
    fetchApi = await axios({
      crossdomain: true,
      method: "post",
      url: "/api/v1/auth/create-password",
      data: _formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        return response;
      })
      .catch(function (error) {});

    var responsdata = fetchApi ? fetchApi.data : null;

    if (responsdata) {
      if (responsdata.error_code == "200") {
        props.login({ phoneNumber: formData.phoneNumber, password: password });
      }
    } else {
    }
  }
  const onBack = () => setFormTag(null);

  function validateOtp(props) {
    setFormData((prevProps, state) => ({
      ...prevProps,
      otp: props.otp,
    }));
    setFormTag(props.formTag);
  }

  async function onResentVerification() {
    var _formData = new FormData();
    var fetchApi = null;
    _formData.append("request_id", formData.requestId);
    _formData.append("phone_number", formData.phoneNumber);
    fetchApi = await axios({
      crossdomain: true,
      method: "post",
      url: "/api/v1/resend-otp",
      data: _formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (response) => {
        const { data } = response;
        try {
          const { error_code, error_message, id = null, validIn = 0 } = data;
          if (["40T1", "200"].indexOf(error_code) >= 0) {
            if (error_code == "200") {
              setFormData((prevProps, state) => ({
                ...prevProps,
                requestId: id,
                validIn: validIn,
              }));
              props.setCount(90);
            } else if (error_code == "20A") {
            }
          } else {
          }
        } catch (e) {}
      })
      .catch(function (response) {});
  }
  const forgotButton = () => {
    return (
      <Button
        block
        onClick={onForgotPassword}
        style={{ border: "none", background: "none", boxShadow: "none" }}
        size="large"
        htmlType="button"
      >
        Lupa PIN?
      </Button>
    );
  };

  function onForgotPassword() {
    history.push("/forgot-password");
  }
  useEffect(() => {
    switch (formTag) {
      case "password_form":
        setPasswordVisible(true);
        setHeader("Masukkan Kode Pin");
        break;
      case "otp_form":
        setOtpVisible(true);
        setHeader("Masukkan Kode OTP");
        break;
      case "activation_form":
        setActVisible(true);
        setHeader("Buat Kode Pin");
        break;
      case "confrim_activation_form":
        setConfirmVisible(true);
        setHeader("Masukkan Ulang Kode Pin ");
        break;
      default:
        setPasswordVisible(false);
        setOtpVisible(false);
        setActVisible(false);
        setConfirmVisible(false);
        break;
    }
  }, [formTag]);

  return (
    <div className="app-container auth-container">
      <div className="header-login" style={imgBack}>
        <img
          className="banner-login mb-5 mt-5"
          src="./images/banner_login.png"
          alt=""
        />

        <label className="text-center" style={header_Title}>
          Solusi Kesejahteraan Digital
        </label>
        <label className="font-content text-center text-white">
          Nikmati berbagai layanan kesejahteraan <br/>
          dalam satu genggaman
        </label>
      </div>
      <Layout className="layout login-form-inner">
        <Content>
          <Modal
            footer={false}
            width={420}
            title="Lupa PIN"
            visible={showForgotPasswordModal}
          >
            <ForgotPasswordRequest />
          </Modal>

          <div className="main-wrapper px-3 py-3">
            <div className="login-card">
              <div className="login-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItem: "center",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <label className="font-title"> Masuk</label>
                  <label className="font-SubItem-12">
                    Selamat Datang di{" "}
                    <span
                      className="text-primary"
                      style={{
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "19.2px",
                      }}
                    >
                      PaidbaQ
                    </span>
                  </label>
                </div>

                <LoginPhoneNumber
                  phoneNumberRef={phoneNumberRef}
                  forgotButton={forgotButton}
                  formData={formData}
                  onSubmit={onSubmitPhoneNumber}
                />
                <LoginOtp
                  formData={formData}
                  data={props}
                  onPrevious={goToPhoneNumberForm}
                  onSubmit={validateOtp}
                  onResentVerification={onResentVerification}
                  otpVisible={otpVisible}
                  title={header}
                />
                <LoginActivation
                  formData={formData}
                  data={props}
                  onPrevious={goToPhoneNumberForm}
                  onSubmit={firstTimeLogin}
                  actVisible={actVisible}
                  title={header}
                />
                <LoginActivationConfirmPin
                  formData={formData}
                  data={props}
                  tmpPin={tmpPin}
                  onPrevious={goToActivation}
                  onSubmit={firstTimeLogin}
                  confirmVisible={confirmVisible}
                  title={header}
                />
                <LoginPassword
                  isFailed={props.isFailed}
                  onBack={onBack}
                  onSubmit={requestToken}
                  passwordVisible={passwordVisible}
                  title={header}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

const imgBack = {
  background: `url('/images/background_login.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const header_Title = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  color: "#FFFFFF",
};

export default connect(
  (state) => ({
    isLoggedIn: state.authReducer.isLoggedIn !== false,
    authReducer: state.authReducer,
    isFailed: state.isFailed,
  }),
  { loginSuccess, login, setCount }
)(Login);
