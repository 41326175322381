const ActionHistory = {
	ACTION_REPLACE : "REPLACE",
	ACTION_POP : "POP",
	ACTION_PUSH : "PUSH",
}
const alertType = {
	ERROR : "ERROR",
	SUCCESS : "SUCCESS",
	WARNING : "WARNING",
	INFO : "INFO",
};

const alertModel = {
	ALERT : "alert",
	NOTIFICATION : "notification",
	MODAL : "modal",
}

export default {alertModel, alertType, ActionHistory};