import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Space, Row, Col, Drawer } from "antd";
import { connect } from "react-redux";
import authAction from "../../redux/actions/authAction";
import ButtonGradient from "@Components/ButtonGradient";
import CustomPin from "./CustomPin";
const { clearLoginFailed } = authAction;

const LoginPassword = (props) => {
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(false);
  const [passwordHelp, setPasswordHelp] = useState({});
  const onFinishFailed = (errorInfo) => {};
  const passwordValidateStatus = "error";
  const onPrevious = () => props.onBack(null);
  const passwordRef = useRef(null);
  const pinRef = useRef(null);
  const phoneNumberValidationStatus = () => {
    if (props.isFailed) {
      return {
        validateStatus: "error",
        help: "Invalid password",
      };
    }
    return;
  };
  const onFinish = (values) => {
    // setDisableButton(true)
    // console.log(values)
    props.clearLoginFailed();
    props.onSubmit(values.password);
  };

  const clearError = () => {
    // props.clearLoginFailed()
    setPasswordHelp({});
  };
  useEffect(
    (pr) => {
      // console.log("useEffect", props)
      if (props.isFailed == true) {
        setPasswordHelp({ validateStatus: "error", help: "Pin tidak sesuai" });
      }
    },
    [props.isFailed]
  );

  useEffect((pr) => {}, []);
  function onChange(value) {}

  function onClear() {
    pinRef.clear();
  }

  return (
    <>
      <Drawer
        placement="bottom"
        
        className="custom-drawer mobile-drawer"
        onClose={onPrevious}
        visible={props.passwordVisible}
        closable={false}
        closeIcon={false}
      >
        <Row
          style={{ display: "flex", flexDirection: "column" }}
          gutter="12"
        >
          <Col className="text-center">
            <label className="font-title">{props.title}</label>
          </Col>
          <Col>
          <Form size="large"
            name="password_form"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed} >
		
            <Form.Item
                label="PIN"
                name="password"
                rules={[
                  { required: true, message: 'Masukkan PIN' }
                ]} 
                {...passwordHelp}
                >
            
              <Input 
                ref={passwordRef}
                maxLength={6}
                pattern="[0-9]*" inputMode="numeric"
                autoFocus={true} 
                className="input-default input-gray text-center" 
                type="password" 
                onChange={clearError}
                />
            </Form.Item>

            <Form.Item >
              <Row  gutter={24}>
                
                <Col span={24}>
                  <ButtonGradient label="Selanjutnya" onLoading={disableButton} size="large" onDisabled={disableButton} />
                  
                </Col>
              </Row>
            </Form.Item>
            </Form>
            {/* <CustomPin onFinish={onFinish} onBack={onPrevious} /> */}
          </Col>
        </Row>
        {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
        {/* </div> */}
      </Drawer>
    </>
  );
};

export default connect(
  (state) => ({
    isFailed: state.authReducer.isFailed,
  }),
  { clearLoginFailed }
)(LoginPassword);
