import {addMinutes} from './globalHelper';
import { store } from './../redux/store';
import authAction from '../redux/actions/authAction';
import Cookies from 'universal-cookie';
import ApiCaller from "./ApiCaller"
const app = {};

var CryptoJS = require("crypto-js");
const axios = require('axios').default;
const TAG = 'AuthLib';
const cookies = new Cookies();
const cookies_name = "access_token";
const accessTokenName = "client-key"
const refreshTokenAtName = "exchange-time"
const expiredTokenAtName = "__expired"
const refreshTokenName = "_exchange-key"

Date.prototype.addHours = function(h) {
	this.setTime(this.getTime() + (h*60*60*1000));
  	return this;
}

Date.prototype.addMinutes = function(h) {
	this.setTime(this.getTime() + (h*60*1000));
  	return this;
}

class AuthLib {
	refresh_token = false

	constructor() {
		this.refresh_token = false;	
	}

	_localStorage() {
		try{
			return {
				access_token : cookies.get(accessTokenName),
				refresh_at: cookies.get(refreshTokenAtName),
				expires_in: cookies.get(expiredTokenAtName),
				refresh_token: cookies.get(refreshTokenName)
			};
		}catch(e){
			return false;
		}
	}


	async token(){
		try{
			
			var _localStorage 	= this._localStorage();
			var refresh_at 		= 0;
			let current_time 	= new Date().getTime();
			var formData 		= new FormData();
			var fetchApi 		= null;
			var refresh_token 	= null;
			// console.log(TAG,_localStorage);
			if(_localStorage){
				refresh_at 		= await this.refreshAt();
				refresh_token 	= await this.refreshToken();

				if(refresh_at > 0 &&refresh_at < current_time && this.refresh_token == false && refresh_token != null){
					this.refresh_token = true;
					formData.append('grant_type', 'refresh_token');
					formData.append('refresh_token', refresh_token);
					formData.append('client_id', app.clientId);
					formData.append('client_secret', app.clientSecret);
					formData.append('scope', '');
			    	fetchApi = await axios({
			    		crossdomain: true,
			    	    method: 'post',
			    	    url: "http://localhost:8000/oauth/token",
			    	    data: formData,
			    	    headers: {'Content-Type': 'multipart/form-data' }
				    }).then(response => {
				    	return response;
				    }).catch(function (response) {
				        console.log(TAG, response);
				    });	
				    console.log(TAG, fetchApi);
					if (fetchApi.data.access_token) {
					    var payload = {
					    	access_token : fetchApi.data.access_token, 
					    	expires_in : fetchApi.data.expires_in, 
					    	refresh_token : fetchApi.data.refresh_token
					    };
					    this.setToken(payload);
					} else {
						store.dispatch(authAction.noAuth());
					}
					this.refresh_token = false;

				}else{
					return _localStorage.access_token;
				}

				_localStorage = this._localStorage();
				return _localStorage.access_token;	
			}
			// store.dispatch(authAction.noAuth());
			return false;
		}catch(e){
			console.log(TAG, e.message);
			return false;
		}
	}

	refreshToken(){
		try{
			var _localStorage = this._localStorage();
			if(_localStorage){
				return _localStorage.refresh_token;
			}
			return null;
		}catch(e){
			return null;
		}
	}
	refreshAt(){
		try{
			var _localStorage = this._localStorage();
			if(_localStorage){
				return _localStorage.refresh_at;
			}
			return 0;
		}catch(e){
			return 0;
		}
	}
	expired(){
		try{
			var localStorage = this._localStorage();
			if(this._localStorage()){
				return localStorage.expired;
			}
			return 0;
		}catch(e){
			return 0;
		}
	}

	async getToken() {
		console.log("GETTOKEN");
		try{
			let data = cookies.get(cookies_name);
			if(!data) {

				console.log("authAction.noAuth");
				store.dispatch(authAction.noAuth());
				return false;
			}
			var decrypt = CryptoJS.AES.decrypt(data, "asdh27834gasbc732bes7d3b");
			var decryptedData = decrypt.toString(CryptoJS.enc.Utf8);
			let token_obj = JSON.parse(decryptedData);
			let expired = token_obj.expired;
			// let refresh_at = token_obj.refresh_at || 0;
			let token = token_obj.token;
			// let refresh_token = (token_obj.refresh_token) ? token_obj.refresh_token : '';
			let tk = cookies.get(accessTokenName);

			/**/
			let current_time = new Date().getTime();
			let access_token = cookies.get(accessTokenName)
			let refresh_at = cookies.get(refreshTokenAtName) || 0
			let expires_in = cookies.get(expiredTokenAtName)
			let refresh_token = cookies.get(refreshTokenName)

			console.log({access_token : access_token, expires_in : expires_in, refresh_tokenx : this.refresh_token,
				refresh_at : refresh_at, current_time: current_time
			});
			
			if(refresh_at > 0 && refresh_at <= current_time && this.refresh_token == false){
				
				this.refresh_token = true;
				let api_url = app.apiUrl+"/oauth/token";
				
				console.log(api_url);
				
				var formData = new FormData();
				var fetchApi = null;
				formData.append('grant_type', 'refresh_token');
				formData.append('refresh_token', token_obj.refresh_token);
				formData.append('client_id', app.clientId);
				formData.append('client_secret', app.clientSecret);
				formData.append('scope', '');
		    	fetchApi = await axios({
		    		crossdomain: true,
		    	    method: 'post',
		    	    url: "/oauth/token",
		    	    data: formData,
		    	    headers: {'Content-Type': 'multipart/form-data' }
			    }).then(response => {
			    	return response;
			    }).catch(function (response) {
			        console.log(response);
			    });	
			    console.log(fetchApi);
				if(fetchApi.data.access_token) {
				    var pl = {
				    	token : fetchApi.data.access_token, 
				    	expired : fetchApi.data.expires_in, 
				    	id: null, 
				    	refresh_token : fetchApi.data.refresh_token
				    };
				    this.setToken(pl);
				}
				this.refresh_token = false;
				console.log("ACTION REF TOK")
			}
			
			data = cookies.get(cookies_name);
			decrypt = CryptoJS.AES.decrypt(data, "asdh27834gasbc732bes7d3b");
			decryptedData = decrypt.toString(CryptoJS.enc.Utf8);
			token_obj = JSON.parse(decryptedData);
			expired = token_obj.expired;
			refresh_at = token_obj.refresh_at;
			token = token_obj.token;
			refresh_token = token_obj.refresh_token;
			// id = token_obj.id;
			tk = cookies.get(cookies_name);
			if(tk){

				return tk;
			}else{
				store.dispatch(authAction.noAuth);
				return null;
			}
		} catch(e) {
			return false;
		}
			

	}

	tokenRefreshAt () {

		try{
			let LOCALSTORAGE = this.getToken();
			if (LOCALSTORAGE) 
			{
				LOCALSTORAGE = JSON.parse(LOCALSTORAGE);
				let refresh_at = LOCALSTORAGE.refresh_at;
				return refresh_at;
			}
		}catch(e){
			return false;
		}
	}

	isLoggedIn() {
		try { 

			return cookies.get(accessTokenName);
		
		}catch(e) {
			return false;
		}
	}

	setToken(payload) {
		return new Promise((resolve, reject,) => {
			if(typeof payload === 'undefined') {
				return reject({message : "Error"});
			}
			try {
				let exp = new Date() ;
				console.log(payload)
				const {id, access_token, expires_in, refresh_token } = payload;
				const token_expires_in_minutes = (parseInt(expires_in) / 60) - 2;
				var refresh_at = addMinutes(new Date(), token_expires_in_minutes);
				var cookies_expired = refresh_at;
				refresh_at = refresh_at.getTime();
				
				var storeToken = cookies.set(accessTokenName, access_token, {path : "/", expires: new Date().addMinutes(4)});
				cookies.set(expiredTokenAtName, expires_in, {path : "/", expires: new Date().addMinutes(4)});
				cookies.set(refreshTokenAtName, refresh_at, {path : "/", expires: new Date().addMinutes(4)});
				cookies.set(refreshTokenName, refresh_token, {path : "/", expires: new Date().addMinutes(4)});
				resolve(storeToken);
			} catch(e){
				return reject(e.message);
			}
		});

	}

	removeToken(){
		return new Promise((resolve, reject) => {
			try{
	    		var fetchApi = ApiCaller({
	    			crossdomain: true,
	    			method: 'POST',
	    			url: "/v1/auth/logout",
	    		}).then((response) => {
	    			this.clearCookie()
	    		}).catch((error) => { 
	    			this.clearCookie()
	    		})
				
			}catch(e){
				console.log(TAG, e);
			}
		});
	}

	clearCookie(){
		cookies.remove(cookies_name);
		cookies.remove(accessTokenName);
		cookies.remove(refreshTokenName);
		cookies.remove(refreshTokenAtName);
		cookies.remove(expiredTokenAtName);
		store.dispatch(authAction.noAuth());
	}

	checkAuthorization () {

	}
}

export default (new AuthLib())