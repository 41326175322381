import actions from '../actions/accountAction';
const initState = {  
	accountInfo : {
		id: null,
        name: null,
        email: null,
        phone_number: null,
        email_verified_at: null,
        member_id: null,
        linked_at: null,
        created_at: null,
        updated_at: null,
        nip: null,
        birth_date: null,
        sex: null,
        status: null,
        salary: 0,
        bank_name: null,
        bank_account: null,
        bank_account_name: null,
        company_name: null,
        company_address: null,
        contact_person: null,
        payroll_date: 0,
        balance: 0,
        prorate: 0,
        profile_image: null
	},
	withdrawHistory: {

	},
    transactionHistory : {
        loading: false,
        items : []
    }
};

export default function rootReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.GET_INFO_COMPLETE:
		  	return {
                ...state, 
                withdrawHistory: {
                    ...state
                }, 
                accountInfo: {
                    ...payload} 
                }
        case actions.GET_HISTORY:
            return {
                ...state, 
                transactionHistory : {
                    ...state.transactionHistory,
                    loading : true,
                }
            }
        case actions.GET_HISTORY_COMPLETE:
            return {
                ...state, 
                transactionHistory : {
                    loading : false,
                    items : payload
                }
            }
                
		default:
			return state
	}
}