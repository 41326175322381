import actions from '../actions/appAction';
const initState = {  
	hideHeader : false, 
	headerType : "image", 
	headerText : null, 
	backButton: false,
	backButtonLink: null,
	tabBar: true,
	isBackTap: false,
};

export default function ppReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.HIDE_HEADER:
		  return { hideHeader : true };
		
		case actions.SHOW_HEADER:
		  return { hideHeader : false};
		case actions.SWITCH_HEADER:
		  return { 
		  	hideHeader : false, 
		  	headerType: payload.headerType, 
		  	headerText : payload.headerText,  
		  	withbackground : payload.withbackground,  
		  	backButton: (typeof payload.backButton != "undefined") ? payload.backButton : true ,
		  	backButtonLink: (typeof payload.backButtonLink != "undefined") ? payload.backButtonLink : null 
		  };
		
		default:
			return state;
	}
}