import actions from '../actions/countDownAction';
const initState = {
	time: 90,
};

export default function rootReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.SET_COUNT:
		  	return {
                ...state, 
                time: action.second
            }
		default:
			return state
	}
}