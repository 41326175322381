import { all, takeEvery, put, fork } from 'redux-saga/effects'
import actions from '../actions/luckydrawAction'
import callApi from '../../helpers/CallApi';
import ApiCaller from "../../helpers/ApiCaller"
import AuthHelpers from '../../helpers/authHelper'
import authAction from '../actions/authAction'
import { store } from '../store'
const axios = require('axios').default
const queryString = require('query-string')
const {CallApi} = callApi;

export function* getLd() {

	yield takeEvery(actions.GET_LD, function*(data) {
		
		var fetchApi = yield ApiCaller({
			crossdomain: true,
			method: 'GET',
			url: '/v1/luckydraw/list',
		}).then((response) => {
			return response
			
		}).catch((error) => { 
	    	const { response = {} } = error;
	    	const { data: responseData = {} } = response
			return response
	    	
		})
        // console.log(fetchApi)
    	try{
    		const { status, data: responseData } = fetchApi
    		if(status == 200) {
    			const { error_code, payload } = responseData
    			if(error_code == "0200" || error_code == "200"){
	    			yield put({
		    			type : actions.GET_LD_COMPLETE,
		    			payload : payload
		    		})
		    	}
    		} else if( status == 401) {
    			AuthHelpers.removeToken();
			    store.dispatch(authAction.noAuth());
			    return
    		}
    	} catch(e) {

    	}
	})

}

export default function* rootSaga() {
	yield all([
		fork(getLd)
	]);
}


