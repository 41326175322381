import React, { useState, useEffect, useRef, createRef } from "react"
import { Form, Input, Button, Space, Row, Col, Typography } from 'antd'
import { connect } from 'react-redux';
import authAction from '../redux/actions/authAction'
const { clearLoginFailed } = authAction
const { Text } = Typography

function PinFieldComponent({style, ...props}){
	var numberCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8]
	const arrLength = props.length;
	const arr = new Array(props.length).fill(0)
	const elRefs = useRef([]);
	if (elRefs.current.length !== arrLength) {
    elRefs.current = Array(arrLength).fill().map((_, i) => elRefs.current[i] || createRef());
  }
	useEffect(() => {
    	elRefs.current[0].current.focus()
	}, [])

	useEffect(() => {

		if(props.clearInput === true) {
			elRefs.current.map((x, y) => {
				x.current.value = ""
			})
			elRefs.current[0].current.focus({cursor: "end"})
			props.onClearValue()
		}

	}, [props.clearInput])
	var numberPattern = /\d+/g;
	const getValue = () => {
		var value = elRefs.current.map((x, y) => (x.current.value))
		var valueString = value.join("")
		return valueString
	}
  	const handleKeyup = index => (e) => {
	  	
	  	var a = e.target.value;
	  	var keyCode = e.keyCode;
	  	if(numberCodes.indexOf(keyCode) < 0) {
	  		elRefs.current[index].current.value = ""
	  		return false
	  	}
		if(!a){
			if (elRefs.current[index - 1]) {
				elRefs.current[index - 1].current.focus({cursor: "end"});
				elRefs.current[index - 1].current.select();
			} 
		} else {
			if(a.match(numberPattern)){
				props.onChange({value: getValue()})
    		if (elRefs.current[index + 1]) {
    			elRefs.current[index].current.blur()
    			elRefs.current[index + 1].current.focus()
    		} else {
    			var value = elRefs.current.map((x, y) => (x.current.value))
    			var valueString = value.join("")
					elRefs.current[index].current.blur()
    			props.onPinComplete({value: valueString})
    		}
			} else {
				elRefs.current[index].current.value = ""
			}
		}
  }
	return(
		<Row key={props.key} style={style} gutter={4}>
		{arr.map((el, i) => {
			return(<>
				<Col key={`col_${i}`} span={4}>
					<input 
						id={`input_${i}`}
						inputmode="numeric"
						key={`input_${i}`}
						name={`pin_${i}`}
						style={{width: "100%", height: "42px", inputSecurity: "square"}}
						onKeyUp={handleKeyup(i)}
						ref={elRefs.current[i]}
						maxLength={1}
						className="input-default input-gray text-center" 
						type="password" 
						/>
					
				</Col>
			</>)}
		)}
		</Row>
	)
}

export default connect(
	state => ({
		isFailed: state.authReducer.isFailed
	}),
	{ clearLoginFailed }
)(PinFieldComponent)