import React, { useState } from "react";
import { Modal, Drawer, Row, Col } from "antd";
import ButtonGradient from "./ButtonGradient";

const ModalAlert = (props) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [hold, setHold] = useState(false);
    const renderImage = () => {
        if(props.types == "success") {
            return <img style={{ width: "250px", margin:"0 auto"}} src={process.env.PUBLIC_URL +"/images/success.gif"} alt="" />
        } else if(props.types == "locationpermission") {
            return <img style={{ width: "60px", margin:"36px auto"}} src={process.env.PUBLIC_URL +"/images/Location.png"} alt="" />
        } else{
            return <img style={{ width: "120px", margin:"0 auto"}} src={process.env.PUBLIC_URL +"/images/failed.gif"} alt="failed gif" />
        }
    };
    function renderRxtraButton () 
    {
        if(props.extraButton) {
            return props.extraButton
        } else{
            return <></>
        }
    }
    return (
        <Drawer
        
            placement="bottom"
            width={420}
            height="auto"
            className="custom-drawer mobile-drawer"
            visible={props.onVisible}
            closable={false}
            closeIcon={false}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {renderImage()}
                <label className="font-title text-center">{props.title}</label>
                <p
                    className="font-content text-center"
                    style={{ color: "#636D72" }}
                >
                    {props.desc}
                </p>
                <ButtonGradient
                    label={props.labelButton}
                    onLoading={loadingButton}
                    onDisabled={hold}
                    size="large"
                    onclick={props.OnClick}
                />
                {renderRxtraButton()}
            </div>
        </Drawer>
    );
};

export default ModalAlert;
