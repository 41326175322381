import React, { useEffect, useState } from "react"
import { Row, Col } from 'antd';
import { NavLink} from "react-router-dom"
import {history} from "@Store"
import {
  HomeOutlined,
  UserOutlined,
  FileProtectOutlined
} from '@ant-design/icons';
const TabBar = (props) => {
	const [selectedPathName, setSelectedPathName] = useState("")
	useEffect(() => {
		const pathName = window.location.pathname
		var pathNameOptions = pathName.split("/")
		pathNameOptions = pathNameOptions.filter((val, idx) => (val != ""))
		if(pathNameOptions.length == 0) {
			setSelectedPathName("")
		}else{
			setSelectedPathName(pathNameOptions[0])
		}
		
	}, [history.location])
	return (
		<div className="tab-bar">
		<Row>
		    <Col xs={8} className="text-center" >
		    	<NavLink isActive={(match, location) => {
					// setSelectedPathName("/")
				    if (location.pathname == '/') {
				      return true;
				    }
				    return false;
				    {/*console.log(location.pathname)
				    // only consider an event active if its event id is an odd number
				    const eventID = parseInt(match.params.eventID);
		    		console.log(!isNaN(eventID) && eventID % 2 === 1)
				    return !isNaN(eventID) && eventID % 2 === 1;*/}
				  }} activeClassName="selected-tab" to="/" className="tab-bar-item">
		    		<div className="tab-bar-icon">
		    			{/* <HomeOutlined style={{ fontSize: '18px' }} /> */}
						<img src={selectedPathName == "" ? process.env.PUBLIC_URL +"/images/home_active.png" : process.env.PUBLIC_URL +"/images/home.png"} />
		    		</div>
		    		<label className="tab-bar-title text-8">
		    			Beranda
		    		</label>
		    	</NavLink>
		      
		    </Col>
		    <Col xs={8} className="text-center" >
		      <NavLink activeClassName="selected-tab" to="/transaction-history" className="tab-bar-item">
		      		<div className="tab-bar-icon">
					  <img src={selectedPathName == "transaction-history" ? process.env.PUBLIC_URL +"/images/transaksi_active.png" : process.env.PUBLIC_URL +"/images/transaksi.png"} />
		    		</div>
		    		<label className="tab-bar-title text-8">
		    			Transaksi
		    		</label>
		    	</NavLink>
		    </Col>
		    <Col xs={8} className="text-center">
		      <NavLink activeClassName="selected-tab" to="/account" className="tab-bar-item">
		      		<div className="tab-bar-icon">
					  <img src={selectedPathName == "account" ? process.env.PUBLIC_URL +"/images/user_active.png" : process.env.PUBLIC_URL +"/images/user.png"} />
		    		</div>
		    		<label className="tab-bar-title text-8">
		    			Saya
		    		</label>
		    	</NavLink>
		    </Col>
		  </Row>
		  </div>
	)
}

export default TabBar