import React, {useState, useEffect} from "react"
import { Row, Col, Card, Form, Button, Input } from 'antd'
import { Link} from "react-router-dom"
import TabBar from '../components/TabBar'
import appAction from "../redux/actions/appAction"
import {connect} from 'react-redux'

const { Meta } = Card;
const { switchHeader } = appAction
const Bills = (props) => {
	const [form] = Form.useForm();
	const onFinish = (values) => {

	}

	const onFinishFailed = errorInfo => {

	}

	useEffect(() => {
		props.switchHeader({headerType: "image", headerText: null})
		document.getElementsByClassName('site-layout-background')[0].classList.remove("with-background")
		return () => {
			
		}
	}, [])
	return(
		<div className="pb70">
			
				
			<TabBar />
		</div>
	)
}
export default connect(
  state => ({
  	appReducer : state.appReducer
  }),
  { switchHeader }
)(Bills)
