import { createStore, combineReducers, applyMiddleware, compose } from "redux"

import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from './rootReducer';
import rootSaga from './rootSaga';

const createHistory = require("history").createBrowserHistory;
const history = createHistory();

// const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)

  // other store enhancers if any
);

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  enhancer
);
sagaMiddleware.run(rootSaga);
export { store, history };