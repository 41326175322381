import actions from "../actions/productAction";

const initState = {
    loading: false,
    loadingCategories: false,
    currentPage:0,
    totalPage:0,
    totalRecords:0,
    products:[],
    bestSeller:  {
        loading: false,
        currentPage: 0,
        totalPage:0,
        totalRecords:0,
        records: []
    },
    search: {
        loading: false,
        currentPage: 0,
        totalPage:0,
        totalRecords:0,
        records: []
    }, 
    categories: []
}

export default function cartReducer(state = initState, action) {
    const { payload } = action;
    
    switch (action.type) {
        case actions.GET_ALL_PRODUCT:
            return{ 
                ...state, 
                loading: true
            }
        case actions.GET_PRODUCT_COMPLETE:
            return {
                ...state,
                loading: false, 
                products : payload.items,
                currentPage :payload.currentPage,
                totalPage :payload.totalPage,
                totalRecords :payload.totalRecords,
            }
        case actions.GET_BEST_SELLER:
           
            return {
                ...state,
                bestSeller : {
                    ...state.bestSeller,
                    loading : true,
                }
            }
        case actions.GET_BEST_SELLER_COMPLETE:
            return {
                ...state,
                bestSeller : {
                    loading : false,
                    records : payload.items,
                }
            }
        

        case actions.GET_SEARCH:
        
            return {
                ...state,
                search : {
                    ...state.bestSeller,
                    loading : true,
                }
            }
        case actions.GET_SEARCH_COMPLETE:
            return {
                ...state,
                search : {
                    loading : false,
                    records : payload.items,
                }
            }
            
        case actions.GET_CATEGORIES:
            return {
                ...state,
                loadingCategories: true, 
            }

        case actions.GET_CATEGORIES_COMPLETE:
            return {
                ...state,
                loadingCategories: false, 
                categories : payload
            }

        
        default:
            return state;
    }
}