const actions = {
    GET_ALL_PRODUCT : 'GET_ALL_PRODUCT',
    GET_CART : "GET_CART",
    GET_PRODUCT_COMPLETE : 'GET_PRODUCT_COMPLETE',
    GET_CART_COMPLETE : 'GET_CART_COMPLETE',
    GET_NUMBER_CART : 'GET_NUMBER_CART',
    DELETE_ITEM : 'DELETE_ITEM',
    UPDATE_ITEM : 'UPDATE_ITEM',
    GET_SEARCH_PRODUCT : 'GET_SEARCH_PRODUCT',
    GET_SEARCH_PRODUCT_COMPLETE : 'GET_SEARCH_PRODUCT_COMPLETE',

    getAllProduct: (params) => ({
        type: actions.GET_ALL_PRODUCT,
        params: params
    }),

    getCarts: () => ({
        type: actions.GET_CART
    }),

    getSearchProduct: (payload) => ({
        type: actions.GET_SEARCH_PRODUCT,
        payload
    }),

    deleteItem: (payload) => {
        return {

            type: actions.DELETE_ITEM,
            ...payload
        }
    },
    updateItem: (payload) => {
        return {

            type: actions.UPDATE_ITEM,
            ...payload
        }
    }
}

export default actions