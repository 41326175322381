import actions from '../actions/withdrawAction';
const _inquiryResult = {
	transaction_id: null,
	total_balance: null,
	total_withdraw: null,
	admin_fee: null,
	total_fee: null,
	amount_received: null,
	bank_name: null,
	bank_account_no: null,
	bank_account_name: null,
}
const initState = {  
	inquiryResult : _inquiryResult,
};

export default function authReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.PARSE_INQUIRY_RESULT:
			return {
				...state, 
				inquiryResult : {
					transaction_id: typeof payload.transaction_id != "undefined" ? payload.transaction_id : null,
					total_balance: typeof payload.total_balance != "undefined" ? payload.total_balance : null,
					total_withdraw: typeof payload.total_withdraw != "undefined" ? payload.total_withdraw : null,
					admin_fee: typeof payload.admin_fee != "undefined" ? payload.admin_fee : null,
					total_fee: typeof payload.total_fee != "undefined" ? payload.total_fee : null,
					amount_received: typeof payload.amount_received != "undefined" ? payload.amount_received : null,
					bank_name: typeof payload.bank_name != "undefined" ? payload.bank_name : null,
					bank_account_no: typeof payload.bank_account_no != "undefined" ? payload.bank_account_no : null,
					bank_account_name: typeof payload.bank_account_name != "undefined" ? payload.bank_account_name : null,
				}
			}
		default:
			return state;
	}
}