import { all, takeEvery, put, fork } from 'redux-saga/effects'
import actions from '../actions/accountAction'
import callApi from '../../helpers/CallApi';
import ApiCaller from "../../helpers/ApiCaller"
import AuthHelpers from '../../helpers/authHelper'
import authAction from '../actions/authAction'
import { store } from '../store'
const axios = require('axios').default
const queryString = require('query-string')
const {CallApi} = callApi;

export function* getAccountInfo() {
	/*yield takeEvery(actions.GET_INFO, function*(data) {
		
		// var fetchData = null
		const fetchData = yield new CallApi({
    		apiUrl : '/api/v1/account',
    		method : 'GET',
    		crossdomain : true
    	}).post().then((res) => {
    		return res.data;
    	}).catch((error) => {
    		console.log(error)
    		return false;
    	});
    	const {error_code, error_message, payload} = fetchData;
    	if(error_code == "200"){
    		yield put({
    			type : actions.GET_INFO_COMPLETE,
    			payload : payload
    		})
    	}
    	
	})*/


	yield takeEvery(actions.GET_INFO, function*(data) {
		var fetchApi = yield ApiCaller({
			crossdomain: true,
			method: 'GET',
			url: '/v1/account',
		}).then((response) => {
			return response
			
		}).catch((error) => { 
	    	const { response = {} } = error;
	    	const { data: responseData = {} } = response
			return response
	    	
		})

    	try{
    		const { status, data: responseData } = fetchApi
    		if(status == 200) {
    			const { error_code, payload } = responseData
    			if(error_code == "200"){
	    			yield put({
		    			type : actions.GET_INFO_COMPLETE,
		    			payload : payload
		    		})
		    	}
    		} else if( status == 401) {
    			AuthHelpers.removeToken();
			    store.dispatch(authAction.noAuth());
			    return
    		}
    	} catch(e) {

    	}
	})

}

export function* geyHistory() {
	yield takeEvery(actions.GET_HISTORY, function*(data) {
		
		var {params} = data;
        if(params) {
            params = "?"+queryString.stringify(params)
        }
		var fetchApi = yield ApiCaller({
			crossdomain: true,
			method: 'GET',
			url: "/v1/account/transaction-history"+params,
		}).then((res) => {
			const {data} = res;
			const { payload = []} = data
			return payload
		}).catch((error) => {
			return []
		 });
		yield put({
			type : actions.GET_HISTORY_COMPLETE,
			payload : fetchApi
		})
    	
	})


	// GET_HISTORY_COM

}



export default function* rootSaga() {
	yield all([
		fork(getAccountInfo),
		fork(geyHistory)
	]);
}



/*

import { all, takeEvery, put, fork } from 'redux-saga/effects'
import actions from '../actions/accountAction'
import callApi from '@Helpers/CallApi';
import ApiCaller from "@Helpers/ApiCaller"
import { store } from '../../redux/store';
import AuthHelpers from '@Helpers/authHelper';
const axios = require('axios').default
const queryString = require('query-string')
const {CallApi} = callApi;


export function* getAccountInfo() {
	yield takeEvery(actions.GET_INFO, function*(data) {
		var fetchApi = ApiCaller({
			crossdomain: true,
			method: 'GET',
			url: "/v1/account",
		}).then((response) => {
			const {data} = response
			switch (data.error_code) {
				case "200" :
				yield put({
					type : actions.GET_INFO_COMPLETE,
					payload : payload
				})
					
				break
				default : 

				break
			}
		}).catch((error) => { 
	    	const { response } = error;
	    	const { data: responseData } = response
	    	if(response.status == 401) {
		    	AuthHelpers.removeToken();
		    	store.dispatch(authAction.noAuth());
	    		return
	    	} 
		})
    	
	})

}



export default function* rootSaga() {
	yield all([
		fork(getAccountInfo)
	]);
}*/