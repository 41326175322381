const actions = {
	GET_INFO: "GET_INFO",
	GET_INFO_COMPLETE: "GET_INFO_COMPLETE",
	GET_WITHDRAW_HISTORY: "GET_WITHDRAW_HISTORY",
	GET_WITHDRAW_HISTORY_COMPLETE: "GET_WITHDRAW_HISTORY_COMPLETE",
	GET_BALANCE: "GET_BALANCE",
	GET_BALANCE_COMPLETE: "GET_BALANCE_COMPLETE",
	GET_HISTORY: "GET_HISTORY",
	GET_HISTORY_COMPLETE: "GET_HISTORY_COMPLETE",

	getInfo: () => ({
		type: actions.GET_INFO
	}),
	getHistory: (params = {}) => ({
		type: actions.GET_HISTORY,
		params: params
	}),

	getWithdrawtHistory: (payload) => ({
		type: actions.GET_WITHDRAW_HISTORY,
		payload
	}),

	getBalance: () => ({
		type: actions.GET_BALANCE
	})
}

export default actions