import AuthHelpers from './authHelper'
const axios = require('axios').default

const api = axios.create({
  	baseURL: '/api/',
  	headers: {
  		'Accept': 'application/json',
		'Content-Type': "application/json",
	},
  	timeout: 10000,
})
api.interceptors.request.use(async function (config) {
	const token = await AuthHelpers.token()
    config.headers["Authorization"] = "Bearer " +token;
    return config;
}, function (error) {
    return Promise.reject(error);
});
export default api