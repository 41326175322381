
import React from "react";
import { ShopOutlined,
TeamOutlined,
UnorderedListOutlined, 
CalculatorOutlined ,
MailOutlined, 
AppstoreOutlined, 
SettingOutlined, 
DatabaseOutlined, BookOutlined, DollarCircleOutlined , BarChartOutlined} from '@ant-design/icons';

import Dashboard from '../Dashboard';
import Bills from '../Bills';

import asyncComponent from '../../helpers/AsyncFunc';
const State = (props) => {
	return {...props};
}
const iconStyle = {
	verticalAlign : "middle"
}
const Item = ({
	visibility = "visible",
	name = "Name",
	label = "Label",
	search = "",
	hash = "",
	icon = "",
	component = null,
	forceRefresh = true,
	state = State({}),
}) => {
	return {
		visibility : visibility,
		name : name,
		label : label,
		search : search,
		hash : hash,
		icon : icon,
		component : component,
		forceRefresh : forceRefresh,
		state : state
	}
}

const items = [
	{
		name : "dashboard", 
		label : "Home",
		pathname: "/",
		search: "",
		hash: "",
		icon : <MailOutlined style={iconStyle}  />,
		// component : "dashboard", 
		forceRefresh : true,
		component : asyncComponent(() => import("../Dashboard")),
		state : {
			hideMenu : false,
		}
	},
	{
		name : "Transacation", 
		label : "Transaction",
		pathname: "/transaction-history",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import("../transaction/TransactionHistory")),
		state : {
			hideMenu : false,
		}
	},
	{
		name : "Transacation-Detail", 
		label : "Transaction Detail",
		pathname: "/transaction-history/detail/:transaction_id",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import("../transaction/TransactionHistoryDetail")),
		state : {
			hideMenu : false,
		}
	},
	{
		name : "Withdraw", 
		label : "Withdraw",
		pathname: "/withdraw",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import("../withdraw/Withdraw")),
		state : {
			hideMenu : false,
		}
	},
	{
		name : "Absence", 
		label : "Absence",
		pathname: "/absence",
		search: "",
		hash: "",
		icon : null,
		forceRefresh : true,
		component : asyncComponent(() => import("../absence/Absence")),
		state : {
			hideMenu : false,
		}
	},
	{
		name : "account", 
		label : "Account",
		pathname: "/account",
		search: "",
		hash: "",
		icon : <TeamOutlined style={iconStyle}  />,
		// component : "account", 
		forceRefresh : true,
		component : asyncComponent(() => import("../Account")),
		state : {
			hideMenu : false,
		}
	},
	{
		name			: "notification",
		label			: "Notification",
		pathname		: "/notification",
		search			: "",
		hash			: "",
		icon			: <TeamOutlined style={iconStyle} />,
		forceRefresh	: true,
		component		: asyncComponent(() => import("../Notification")),
		state			: {
			hideMenu	: false
		}
	},
	{
		name			: "groceries",
		label			: "Groceries",
		pathname		: "/grocery",
		search			: "",
		hash			: "",
		icon			: <TeamOutlined style={iconStyle} />,
		forceRefresh	: true,
		component		: asyncComponent(() => import("../groceries/MainGroceries")),
		state			: {
			hideMenu	: false
		}
	},
	{
		name			: "groceries-list",
		label			: "List Groceries",
		pathname		: "/grocery/products",
		search			: "",
		hash			: "",
		icon			: <TeamOutlined style={iconStyle} />,
		forceRefresh	: true,
		component		: asyncComponent(() => import("../groceries/ListProduct")),
		state			: {
			hideMenu	: false
		}
	},
	{
		name			: "best-seller",
		label			: "Best Seller",
		pathname		: "/grocery/best-seller",
		search			: "",
		hash			: "",
		icon			: <TeamOutlined style={iconStyle} />,
		forceRefresh	: true,
		component		: asyncComponent(() => import("../groceries/BestSeller")),
		state			: {
			hideMenu	: false
		}
	},
	{
		name			: "product-detail",
		label			: "Product Detail",
		pathname		:"/product-detail/:product_id",
		search			:"",
		hash			:"",
		icon			:<TeamOutlined style={iconStyle} />,
		forceRefresh	:true,
		component		:asyncComponent(() => import("../groceries/DetailProduct")),
		state			: {
			hideMenu	:true
		}
	},
	{
		name			: "search-product",
		label			: "Search Product",
		pathname		:"/search-product",
		search			:"",
		hash			:"",
		icon			:<TeamOutlined style={iconStyle} />,
		forceRefresh	:true,
		component		:asyncComponent(() => import("../groceries/SearchProduct")),
		state			: {
			hideMenu	:true
		}
	},
	{
		name			: "cart",
		label			: "Cart",
		pathname		:"/cart",
		search			:"",
		hash			:"",
		icon			:<TeamOutlined style={iconStyle} />,
		forceRefresh	:true,
		component		:asyncComponent(() => import("../groceries/Cart")),
		state			: {
			hideMenu	:true
		}
	},
	{
		name			: "checkout",
		label			: "Checkout",
		pathname		:"/checkout/:orderId",
		search			:"",
		hash			:"",
		icon			:<TeamOutlined style={iconStyle} />,
		forceRefresh	:true,
		component		:asyncComponent(() => import("../groceries/CekOut")),
		state			: {
			hideMenu	:true
		}
	},
	{
		name			: "payment",
		label			: "Payment",
		pathname		:"/payment/:orderId",
		search			:"",
		hash			:"",
		icon			:<TeamOutlined style={iconStyle} />,
		forceRefresh	:true,
		component		:asyncComponent(() => import("../groceries/Payment")),
		state			: {
			hideMenu	:true
		}
	}
];

export default items;