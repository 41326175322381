import React, { useState } from "react";
import { connect } from "react-redux";
import {
    NavLink,
    Redirect,
    Link,
    Route,
    Switch,
    useHistory,
} from "react-router-dom";

import { Row, Col } from "antd";

import Login from "../auth/Login";
import NotFound from "..//NotFound";
import items from "./menu";
import RouteMaps from "../../app/RouteMaps";
import extraRoute from "./extraMenu";
import { history } from "../../redux/store";
import { LeftOutlined } from "@ant-design/icons";
import AuthLib from "../../helpers/AuthLib";

const MainLayout = (props) => {
    const bw = useHistory();
    const { url } = props.match;
    const { isLoggedIn, location } = props;
    const pathname = "/";

    useState(() => {});

    const goBack = () => {
        if (props.appReducer.backButtonLink && props.appReducer.backButton) {
            // bw.push(props.appReducer.backButtonLink);
            bw.goBack()
        }
    };
    const logout = () => {
        AuthLib.removeToken();
    };

    return (
        <>
            <Switch>
                <Route
                    stric
                    exact={true}
                    history={history}
                    path={"/login"}
                    isLoggedIn={isLoggedIn}
                    component={(props) =>
                        !isLoggedIn ? (
                            <Login {...props} />
                        ) : (
                            (window.location = pathname)
                        )
                    }
                ></Route>
                <Route {...props}>
                    <div
                        className="site-layout-background app-container"
                        style={{ padding: 0 }}
                    >
                        <main>
                            {props.appReducer.hideHeader == false ? (
                                props.appReducer.withbackground == "default" ? (
                                    <div className="app-header-white">
                                        {props.appReducer.headerType ==
                                        "image" ? (
                                            <>
                                            {props.appReducer.backButton ==
                                                true ? (
                                                    <button
                                                        onClick={goBack}
                                                        className="header-back-button"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <LeftOutlined />
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                                <img src="./images/Final-logo-paidbaq-07.png" alt="" />
                                            </>
                                        ) : (
                                            <h5 className="header-text">
                                                {props.appReducer.backButton ==
                                                true ? (
                                                    <button
                                                        onClick={goBack}
                                                        className="header-back-button"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <LeftOutlined />
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                                {props.appReducer.headerText}
                                            </h5>
                                        )}
                                    </div>
                                ) : (
                                    <div className="app-header">
                                        {props.appReducer.headerType ==
                                        "image" ? (
                                            <img src="./images/Final-logo-paidbaq-07.png" alt="" />
                                        ) : (
                                            <h5 className="header-text">
                                                {props.appReducer.backButton ==
                                                true ? (
                                                    <button
                                                        onClick={goBack}
                                                        className="header-back-button"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <LeftOutlined />
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                                {props.appReducer.headerText}
                                            </h5>
                                        )}
                                    </div>
                                )
                            ) : (
                                <></>
                            )}
                            <RouteMaps
                                history={props.history}
                                extraRoute={extraRoute}
                                items={items}
                                url={url}
                            />
                            {/* <Route path="*" exact={true}>
                                <NotFound />
                            </Route> */}
                        </main>
                    </div>
                </Route>
            </Switch>
        </>
    );
};
export default connect(
    (state) => ({
        appReducer: state.appReducer,
    }),
    {}
)(MainLayout);
