import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import env from '../environment';
import ApiCaller from "./ApiCaller";


const firebaseConfig = {
    apiKey: "AIzaSyBSMHp6Ic8hXoQ6m-nU8esEAXgMiMV-CUA",
    authDomain: "paidbaq-359106.firebaseapp.com",
    projectId: "paidbaq-359106",
    storageBucket: "paidbaq-359106.appspot.com",
    messagingSenderId: "749576131878",
    appId: "1:749576131878:web:695dc9f12f2e127d015de7",
    measurementId: "G-2YEXR8316M"
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: env.vapidKey}).then((currentToken) => {
    if (currentToken) {
      ApiCaller({
        url: "/v1/notification/send-token-firebase",
        method: "POST",
        data: { token: currentToken, type_device: "web" },
        crossdomain: true,
      })
        .then((res) => {
          if(res.error_code == 200){
            setTokenFound(true);
          }
        })
        .catch((error) => {});
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});