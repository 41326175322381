const actions = {
	
	LOGIN : "LOGIN",
	REFRESH_TOKEN : "REFRESH_TOKEN",
	LOGIN_SUCCESS : "LOGIN_SUCCESS",
	LOGIN_FAILED : "LOGIN_FAILED",
	CHECK_AUTHORIZATION : "CHECK_AUTHORIZATION",
	CLEAR_LOGIN_FAILED : "CLEAR_LOGIN_FAILED",
	LOGIN_SUCCESSX : "LOGIN_SUCCESSX",
	LOGOUT: "LOGOUT",
	loginSuccess : (payload) => ({
		type : actions.LOGIN_SUCCESSX,
		payload
	}),
	login : (payload) => {
		var a = {
			type : actions.LOGIN,
			payload
		}
		return a
	},
	checkAuthorization: () => ({
		type: actions.CHECK_AUTHORIZATION
	}),
	clearLoginFailed: () => ({
		type: actions.CLEAR_LOGIN_FAILED
	}),
	refreshToken : (payload) => ({
		type : actions.REFRESH_TOKEN,
		payload
	}),
	noAuth: (payload) => ({type: actions.LOGOUT})
}

export default actions;