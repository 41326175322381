import { Card } from "antd";
import React from "react";

function FiturShimmer ({dataLength = 5, ...props }) 
{

    function getShimmer() {
        var ab = [];
        for(var i = 0; i < dataLength; i++ )
        {
            ab.push(<div key={`_category-feature-${i}`} className="shine" style={{ height: 60,marginLeft: 6, marginRight: 6, flex: "0 16.5%"}}></div>)
        }
        return ab 
    }
    return (
        <>
        <div className="shine" style={{ height: 24, width: 100, marginTop: 70, marginBottom : 12}}></div>
        <div className="categories-container d-flex flex-wrap" >{getShimmer()}</div>
        </>
    )
}

export default FiturShimmer