import React, { useState, useEffect } from "react";
import { Layout, Button, message,Form, Input, Row, Col} from "antd";
import { connect } from "react-redux";
import countDownAction from "../../redux/actions/countDownAction";
import OTPComponent from "../../components/OTPComponent";
const { setCount } = countDownAction;

const axios = require("axios").default;
const { Header, Content, Footer } = Layout;

const ForgotPasswordOtp = (props) => {
  // const [form] = Form.useForm();
  const [isLoading, setIsloading] = useState(false);
  const [resendCount, setResentCount] = useState(0);
  const [isOTPFailed, setIsOTPFailed] = useState(false);
  const [helpMessage, setHelpMessage] = useState({})
  const onFinishFailed = errorInfo => {}
  function onPrevious() {
    props.onPrevious();
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (props.time >= 0) {
        var x = props.time - 1;
        props.setCount(props.time - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  async function onFinish(values) {
    var formData = new FormData();
    var fetchApi = null;
    // console.log(props.formData.requestId)

    formData.append("phone_number", props.formData.phoneNumber);
    formData.append("otp", values.otp);
    formData.append("id", props.formData.requestId);
    formData.append("reason", "reset-password");
    fetchApi = await axios({
      crossdomain: true,
      method: "post",
      url: "/api/v1/auth/forgot-password/verify",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (response) => {
        const { data } = response;

        try {
          // fix toeken undefined
          const { error_code, error_message, token, id = null } = data;
          if (["200"].indexOf(error_code) >= 0) {
            props.onSubmit({ otp: values.otp, token : token ,  formTag: "activation_form" });
          } else if (error_code == "419") {
            setIsOTPFailed(true);
            message.error("OTP expired");
          } else if (error_code == "40T1") {
            message.error("OTP tidak valid");
            setIsOTPFailed(true);
          }
          setTimeout(() => {
            setIsOTPFailed(false);
          }, 200);
        } catch (e) {}
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  const renderCountDown = () => {
    return props.time > 0 ? (
      <span>{props.time}</span>
    ) : (
      <Button
        size="small"
        disabled={resendCount > 0 && props.time > 0}
        onClick={resendVerificationCode}
        style={{ border: "none", background: "none", boxShadow: "none" }}
        className="text-center mt-2"
        block
      >
        Kirim ulang
      </Button>
    );
  };
  function resendVerificationCode() {
    setResentCount(1);
    props.onResentVerification();
  }
  return (
    <>
      <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
        <label className="font-title text-center">Masukkan Kode OTP</label>
      </div>

      <p className="text-center">
        Tidak terima kode? {renderCountDown()}
      </p>
      <Form size="large"
			name="password_form"
			layout="vertical"
			autoComplete="off"
			onFinish={onFinish}
			onFinishFailed={onFinishFailed} >
			
			<Form.Item
			    label=""
			    name="otp"
			    rules={[
			    	{ required: true, message: 'Masukkan OTP' }
			    ]} 
			    {...helpMessage}
			    >
			
				<Input 
					maxLength={4}
					autoFocus={true} 
					className="input-default input-gray text-center" 
					type="otp" 
					/>

			</Form.Item>

			<Form.Item >
			{/*{props.time}*/}
				<Row  gutter={24}>
					<Col span={12}>
				 		<Button 
				 			onClick={onPrevious}
				 			block
					 		type="default" 
					 		size="large" 
					 		className="btn-app"
					 		htmlType="button">
							<i className="fas fa-caret-left fa-fw" /> Sebelumnya
						</Button>
					</Col>
					<Col span={12}>
						<Button 
						className="btn-app-primary btn-app"
						block
			 			style={{float: 'right'}} 
			 			type="primary" 
			 			size="large" 
			 			htmlType="submit">
						<i className="fas fa-sign-in-alt fa-fw"></i> &nbsp; Selanjutnya 
					</Button>
					</Col>
					<Col span={24}>
						
						
					</Col>
				</Row>
			
			</Form.Item>
  		</Form>
    </>
  );
};
export default connect(
  (state) => ({
    time: state.countDownReducer.time,
  }),
  { setCount }
)(ForgotPasswordOtp);
