import React, { useState, useEffect } from "react";
import { Drawer, Layout, Modal } from "antd";
import ForgotPasswordPhoneNumber from "./ForgotPasswordPhoneNumber";
import ForgotPasswordOtp from "./ForgotPasswordOtp";
import ForgotPasswordNewPin from "./ForgotPasswordNewPin";
import { connect } from "react-redux";
import env from "../../environment";
import authAction from "../../redux/actions/authAction";
import countDownAction from "../../redux/actions/countDownAction";

const axios = require("axios").default;

const { Content } = Layout;

const { loginSuccess, login } = authAction;
const { setCount } = countDownAction;
const ForgotPasswordRequest = (props) => {
    const [formTag, setFormTag] = useState(null);
    const [formData, setFormData] = useState({
        phoneNumber: null,
        requestId: null,
        password: null,
        token: null,
        otp: null,
        otpFormKey: "initial",
    });
    const [drawerVisible, setDrawerVisible] = useState(false);

    useEffect(() => {});

    function onSubmitPhoneNumber(props) {
        setFormData((prevProps, state) => ({
            ...prevProps,
            phoneNumber: props.phoneNumber,
            requestId: props.id,
            validIn: props.validIn,
        }));
        setFormTag(props.formTag);
        setDrawerVisible(true);
    }

    const goToPhoneNumberForm = () => setFormTag(null);
    const onBack = () => setFormTag(null);

    async function firstTimeLogin(password) {
        var _formData = new FormData();
        var fetchApi = null;
        // _formData.append('grant_type', 'password');
        // _formData.append('client_id', env.apiClientId);
        _formData.append("client_secret", env.apiSecret);
        _formData.append("phone_number", formData.phoneNumber);
        _formData.append("new_password", password);
        _formData.append("otp", formData.otp);
        _formData.append("token", formData.token);
        _formData.append("id", formData.requestId);
        _formData.append("reason", "reset-password");
        _formData.append("scope", "");
        fetchApi = await axios({
            crossdomain: true,
            method: "post",
            url: "/api/v1/auth/forgot-password/create-pin",
            data: _formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                return response;
            })
            .catch(function (error) {});

        var responsdata = fetchApi ? fetchApi.data : null;

        if (responsdata) {
            if (responsdata.error_code === "200") {
                props.login({
                    phoneNumber: formData.phoneNumber,
                    password: password,
                });
            }
        } else {
        }
        // props.login({phoneNumber: formData.phoneNumber, password: password})
    }
    function validateOtp(props) {
        setFormData((prevProps, state) => ({
            ...prevProps,
            otp: props.otp,
            token: props.token,
        }));
        setFormTag(props.formTag);
    }

    async function onResentVerification() {
        var _formData = new FormData();
        var fetchApi = null;
        _formData.append("request_id", formData.requestId);
        _formData.append("phone_number", formData.phoneNumber);
        _formData.append("reason", "reset-password");

        fetchApi = await axios({
            crossdomain: true,
            method: "post",
            url: "/api/v1/resend-otp",
            data: _formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(async (response) => {
                const { data } = response;
                try {
                    const {
                        error_code,
                        error_message,
                        id = null,
                        validIn = 0,
                    } = data;
                    if (["40T1", "200"].indexOf(error_code) >= 0) {
                        if (error_code == "200") {
                            setFormData((prevProps, state) => ({
                                ...prevProps,
                                requestId: id,
                                validIn: validIn,
                            }));
                            props.setCount(90);
                        } else if (error_code == "20A") {
                        }
                    } else {
                    }
                } catch (e) {}
            })
            .catch(function (response) {
                console.log(response);
            });
    }

    return (
        <div className="app-container auth-container">
            <div className="header-login" style={imgBack}>
                <img
                    className="banner-login mb-5 mt-5"
                    src="./images/banner_login.png"
                    alt=""
                />

                <label className="text-center" style={header_Title}>
                    Solusi Kesejahteraan Digital
                </label>
                <label className="font-content text-center text-white">
                    Nikmati berbagai layanan kesejahteraan
                </label>
                <label className="font-content text-center text-white">
                    dalam satu genggaman
                </label>
            </div>
            <Layout className="layout login-form-inner">
                <Content>
                    <div className="site-layout-content">
                        {formTag === null ? (
                            <ForgotPasswordPhoneNumber
                                formData={formData}
                                onSubmit={onSubmitPhoneNumber}
                            />
                        ) : (
                            ""
                        )}
                        <Drawer
                            placement="bottom"
                            width={420}
                            height={580}
                            visible={drawerVisible}
                            closable={false}
                            closeIcon={false}
                            className="custom-drawer mobile-drawer"
                        >
                            {formTag === "otp_form" ? (
                                <ForgotPasswordOtp
                                    formData={formData}
                                    data={props}
                                    onBack={onBack}
                                    onSubmit={validateOtp}
                                    onResentVerification={onResentVerification}
                                />
                            ) : (
                                ""
                            )}
                            {formTag === "activation_form" ? (
                                <ForgotPasswordNewPin
                                    formData={formData}
                                    data={props}
                                    onPrevious={goToPhoneNumberForm}
                                    onSubmit={firstTimeLogin}
                                />
                            ) : (
                                ""
                            )}
                        </Drawer>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};
const imgBack = {
    background: `url('/images/background_login.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
};

const header_Title = {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
};

export default connect(
    (state) => ({
        isLoggedIn: state.authReducer.isLoggedIn !== false,
        authReducer: state.authReducer,
    }),
    { loginSuccess, login, setCount }
)(ForgotPasswordRequest);
