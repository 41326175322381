import React, {useState, useEffect, useRef} from "react"
import { Layout, Form, Input, Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import  countDownAction from '../../redux/actions/countDownAction'
import ButtonGradient from "../../components/ButtonGradient"

const { setCount } = countDownAction
const axios = require('axios').default

const LoginPhoneNumber = (props) => {

	const [form] = Form.useForm();
	const [formTag, setFormTag] = useState(null)
	const [disableButton, setDisableButton] = useState(false)
	const [helpMessage, setHelpMessage] = useState({})
	const phoneNumberRef = useRef(null)

	var timer = null;

	const onFinish = async values => {
		setDisableButton(true)
	    var formData = new FormData()
		var fetchApi = null
    	formData.append('phone_number', values.phone_number)
    	fetchApi = await axios({
    		crossdomain: true,
    	    method: 'post',
    	    url: "/api/v1/user/check",
    	    data: formData,
    	    headers: {'Content-Type': 'multipart/form-data' }
	    }).then(async (response) => {
	    	const {data} = response
	    	try {
	    		const {error_code, error_message, id = null, validIn= 0} = data
	    		if(["20A", "200"].indexOf(error_code) >= 0) {
	    			if(error_code == "200"){
	    				setFormTag("password_form")
	    				props.onSubmit({
	    					phoneNumber: values.phone_number, 
	    					formTag: "password_form", id: id})
	    			}else if(error_code == "20A") {
	    				setFormTag("otp_form")
	    				props.onSubmit({
	    					phoneNumber: values.phone_number, 
	    					formTag: "otp_form", 
	    					id: id,
	    					validIn: validIn})
	    				props.setCount(90)
	    			}
	    		} else{
	    			setHelpMessage({
						validateStatus: "error",
						help: error_message
					})
	    			setFormTag(null)
	    		}

	    	} catch(e) {
	    		setFormTag(null)
	    	}
	    	
	    }).catch(function (response) {
	        console.log(response);
	    });
		setDisableButton(false)
		
	};

	const validatePhoneNumbers = async (rule, value) => {
		return new Promise((resolve, reject) => {
			clearTimeout(timer); 
			setDisableButton(true)
			timer = setTimeout(async () => {
			    var formData = new FormData()
				var fetchApi = null
		    	formData.append('phone_number', value)
		    	fetchApi = await axios({
		    		crossdomain: true,
		    	    method: 'post',
		    	    url: "/api/v1/user/check",
		    	    data: formData,
		    	    headers: {'Content-Type': 'multipart/form-data' }
			    }).then(response => {
			    	const {data} = response

			    	try {

			    		const {error_code, error_message} = data

			    		if(["20A", "200"].indexOf(error_code) >= 0) {
			    			if(error_code == "200"){
			    				setFormTag("password_form")
			    			}else if(error_code == "20A") {
			    				setFormTag("activation_form")
			    				
			    			}
			    			resolve()
			    		} else{
			    			setFormTag(null)
			    			reject()
			    		}

			    	} catch(e) {
			    		setFormTag(null)
			    		reject()
			    	}
			    	
			    }).catch(function (response) {
			        console.log(response);
			        reject("Kode sudah digunakan");
			    });
				setDisableButton(false)
			}, 1000)
		});

    };
	const onFinishFailed = errorInfo => {

	};

	useEffect(() => {
		form.setFieldsValue({
			phone_number : props.formData.phoneNumber,
		});
		props.phoneNumberRef.current.focus()
	},[])
	const clearError = () => setHelpMessage({})
	
	return(
		<>
		
  		<Form
  			scrollToFirstError={true}
  			autoComplete="off"
  			form={form}
  			size="large"
  			name="basic"
  			layout="vertical"
  			onFinish={onFinish}
  			onFinishFailed={onFinishFailed} 
  			
  			>
  			    	{/*{ validator : validatePhoneNumbers, message : "Akun tidak ditemukan"}*/}
  			<Form.Item
  			    label="Nomor HP"
  			    name="phone_number"
  			    rules={[
  			    	{ required: true, message: 'Nomor HP Tidak boleh kosong!' },
  			    ]} 
      			{ ...helpMessage }>
				
				<Input
					inputMode="numeric"
					ref={props.phoneNumberRef}
					onChange={clearError}
					type="number" 
					className="input-default input-gray input-xl" 
					placeholder="Masukkan nomor hp" />
  			 </Form.Item>

  			 
  			 <Form.Item>
		 		<Row  gutter={24}>
		 			
		 			<Col span={24}>
		  			 	{/* <Button 
		  			 		block
		  			 		loading={disableButton} 
		  			 		className="btn-app-primary btn-app btn-xl" 
		  			 		style={{float: 'right'}} 
		  			 		type="primary" 
		  			 		size="large" 
		  			 		htmlType="submit"
		  			 		>
							Masuk
		  				</Button> */}
						<ButtonGradient label="Masuk" onLoading={disableButton} size="large" onDisabled={false} />
		 				
		 			</Col>
		 			<Col span={24} className="mt-2">
		  			 	{props.forgotButton()}
		 			</Col>
		 		</Row>
  			 	
			</Form.Item>
  		</Form>
		  </>
	)
}

// export default LoginPhoneNumber
export default connect(
	state => ({
		time: state.countDownReducer.time,
	}),
	{ setCount }
)(LoginPhoneNumber);
