
import React from "react"
import 'antd/dist/antd.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css"
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import Boot from "./redux/boot";
import PublicRoutes from "./router";

const PaidbaqApp = () => (

  <Provider store={store}>
    <PublicRoutes history={history} />
  </Provider>

)

Boot()
  .then(() => PaidbaqApp())
  .catch(error => console.error(error));
export default PaidbaqApp;