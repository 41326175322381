import React, { useState, useEffect } from "react";
import { Form, Input, Button, Space, Row, Col, message, Drawer } from "antd";
import { connect } from "react-redux";
import countDownAction from "../../redux/actions/countDownAction";
import OTPComponent from "../../components/OTPComponent";
import ButtonGradient from "@Components/ButtonGradient"
const axios = require("axios").default;
const { setCount } = countDownAction;
const LoginOtp = (props) => {
    const [resendCount, setResentCount] = useState(0);
    const onPrevious = () => props.onPrevious();
    const onFinishFailed = errorInfo => {}
    const [isOTPFailed, setIsOTPFailed] = useState(false);
    const [helpMessage, setHelpMessage] = useState({})

    useEffect(() => {
        const timer = setInterval(() => {
            if (props.time >= 0) {
                var x = props.time - 1;
                props.setCount(props.time - 1);
            }
        }, 1000);

        return () => clearInterval(timer);
    });

    async function onFinish(values) {
        
        var formData = new FormData();
        var fetchApi = null;

        formData.append("phone_number", props.formData.phoneNumber);
        formData.append("otp", values.otp);
        formData.append("id", props.formData.requestId);
        fetchApi = await axios({
            crossdomain: true,
            method: "post",
            url: "/api/v1/otp/validate",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(async (response) => {
                const { data } = response;
                try {
                    const { error_code, error_message, id = null } = data;
                    if (["200"].indexOf(error_code) >= 0) {
                        props.onSubmit({
                            otp: values.otp,
                            formTag: "activation_form",
                        });
                    } else if (error_code == "419") {
                        setIsOTPFailed(true);
                        message.error("OTP expired");
                    } else if (error_code == "40T1") {
                        message.error("OTP tidak valid");
                        setIsOTPFailed(true);
                    }
                    setTimeout(() => {
                        setIsOTPFailed(false);
                    }, 200);
                } catch (e) {}
            })
            .catch(function (response) {});
    }

    const renderCountDown = () => {
        return props.time > 0 ? (
            <p
                style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fintSize: "16px",
                }}
            >
                {props.time}
            </p>
        ) : (
            <Button
                size="small"
                disabled={resendCount > 0 && props.time > 0}
                onClick={resendVerificationCode}
                style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                }}
                className="text-center mt-4"
                block
            >
                Kirim ulang
            </Button>
        );
    };

    function resendVerificationCode() {
        setResentCount(1);
        props.onResentVerification();
    }

    return (
        <>
            <Drawer
                placement="bottom"
                className="custom-drawer mobile-drawer"
                height="auto"
                closeIcon={false}
                visible={props.otpVisible}
            >
                <Row
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                    gutter="12"
                >
                    <Col className="text-center">
                        <label className="font-title text-center">
                            {props.title}
                        </label>
                    </Col>
                    <Col>
                        <p className="text-center">
                            {/* Kami telah mengirimkan kode verifikasi (OTP) ke nomor whatsap kamu. 
                          Silahkan periksa whatsapp kamu dan masukkan OTP nya */}
                            Tidak terima kode? {renderCountDown()}
                        </p>
                        {/* <OTPComponent
                            onFinish={onFinish}
                            otpError={isOTPFailed}
                            onBack={onPrevious}
                        /> */}
                        <Form size="large"
                            name="password_form"
                            layout="vertical"
                            autoComplete="off"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed} >
                            <Form.Item
                                label=""
                                name="otp"
                                rules={[
                                    { required: true, message: 'OTP tidak boleh kosong' }
                                ]} 
                                {...helpMessage}
                                >
                            
                                <Input 
                                    maxLength={4}
                                    autoFocus={true} 
                                    className="input-default input-gray input-xl text-center" 
                                    pattern="[0-9]*" inputMode="numeric"
                                    type="text" 
                                    />

                            </Form.Item>

                            <Form.Item >
                            {/*{props.time}*/}
                                <Row  gutter={24}>
                                    <Col span={12}>
                                        <Button 
                                            onClick={onPrevious}
                                            block
                                            type="text"
                                            size="large" 
                                            className="btn-app"
                                            htmlType="button">
                                            <i className="fas fa-caret-left fa-fw" /> Sebelumnya
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                    <ButtonGradient 
                                        icon={<i className="fas fa-sign-in-alt fa-fw" style={{marginRight: 10}}></i>}
                                        style={{marginTop: 0}}
                                        label=" Selanjutnya" 
                                        size="large" 
                                        onDisabled={false} />
                                    </Col>
                                    <Col span={24}>
                                    
                                        {/*<Button 
                                            size="small"
                                            style={{ border: "none", background: "none"}} 
                                            className="text-center mt-3" block>
                                            <i className="fa fa-envelope fa-fw"></i> Kirim kode verifikasi melalui SMS
                                        </Button>*/}
                                        {/*className="btn-app-primary btn-app"*/}
                                        {/*<Button 
                                        block
                                        style={{float: 'right'}} 
                                        type="primary" 
                                        ghost={true}
                                        size="large" 
                                        htmlType="submit">
                                        <i className="fa fa-envelope fa-fw"></i> &nbsp; Kirim kode verifikasi melalui SMS 
                                    </Button>*/}
                                    </Col>
                                </Row>
                            {/*</Row>*/}
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col>
                        
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};
export default connect(
    (state) => ({
        time: state.countDownReducer.time,
    }),
    { setCount }
)(LoginOtp);
