import { all, take, takeEvery, put, fork } from "redux-saga/effects";
import actions from "../actions/productAction";
import ApiCaller from "../../helpers/ApiCaller";
import AuthHelpers from '../../helpers/authHelper';
import authAction from '../actions/authAction';
import { store } from "../store";

const axios = require('axios').default
const queryString = require('query-string')


export function* getAllProduct() {
    yield takeEvery(actions.GET_ALL_PRODUCT, function*(data){
        var {params} = data;
        if(params) {
            params = "?"+queryString.stringify(params)
        }
        // console.log(data)
        var fetchApi = yield ApiCaller({
            crossdomain:true,
            method: 'GET',
            url: `/v1/products${params}`,
        }).then((response)=> {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const { data: responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_PRODUCT_COMPLETE,
                        payload : payload
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}
export function* getSearch() {
    yield takeEvery(actions.GET_SEARCH, function*(data){
        var {params} = data;
        if(params) {
            params = "?"+queryString.stringify(params)
        }
        var fetchApi = yield ApiCaller({
            crossdomain:true,
            method: 'GET',
            url: `/v1/products${params}`,
        }).then((response)=> {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const { data: responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_SEARCH_COMPLETE,
                        payload : payload
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}
export function* getBestSeller() {
    yield takeEvery(actions.GET_BEST_SELLER, function*(data){
        var fetchApi = yield ApiCaller({
            crossdomain:true,
            method: 'GET',
            url: '/v1/products/best-seller',
        }).then((response)=> {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const { data: responseData = {} } = response
            return response
        })

        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload } = responseData
                if(error_code == "200"){
                    yield put({
                        type : actions.GET_BEST_SELLER_COMPLETE,
                        payload : payload
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}
export function* getCategories(){
    yield takeEvery(actions.GET_CATEGORIES, function*(data){
        var fetchApi = yield ApiCaller({
            crossdomain : true,
            method      : "GET",
            url         : "/v1/products/category"
        }).then((response) => {
            return response
        }).catch((error) => {
            const { response = {} } = error;
            const {data : responseData = {} } = response
            return response
        })
        try{
            const { status, data: responseData } = fetchApi
            if(status == 200 ){
                const { error_code, payload } = responseData
                if(error_code == "200"){
                    
                    yield put({
                        type : actions.GET_CATEGORIES_COMPLETE,
                        payload : payload
                    })
                }
            }else if(status == 401){
                AuthHelpers.refreshToken();
                store.dispatch(authAction.noAuth);
                return
            }
        }catch(e){

        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAllProduct),
        fork(getSearch),
        fork(getBestSeller),
        fork(getCategories)
    ]);
}