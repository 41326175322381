import React, { useState } from "react"
import { Drawer, Form, message, Row, Col } from 'antd'
import CustomPin from "./CustomPin"
const LoginActivationConfirmPin = (props) => {
	const [form] = Form.useForm()
	const onFinish = values => {
		handleComplete(values)

	}
	const onPrevious = () => props.onPrevious()
	
	function handleComplete(e){
  		if(props.tmpPin === e) {
			props.onSubmit(e)
  		} else {
			message.error("PIN tidak cocok")
  		}
  	}
	return(
	<Drawer
		placement="bottom"
		width={420}
        height={494}
        className="custom-drawer"
		visible={props.confirmVisible}
		closable={false}
		closeIcon={false}
		>
		<Row
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center" }}
          gutter="12"
        >
          <Col className="text-center">
            <label className="font-title text-center">{props.title}</label>
          </Col>
          <Col>
            <CustomPin onFinish={onFinish} onBack={onPrevious} />
          </Col>
        </Row>
		{/* <div style={{ display: "flex", justifyContent: "center" }}>
          <label className="font-title text-center">{props.title}</label>
        </div>
		<CustomPin onFinish={onFinish} onBack={onPrevious} /> */}
	</Drawer>
	
	)
}

export default LoginActivationConfirmPin

