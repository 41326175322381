
import authReducer from './reducers/authReducer'
import appReducer from './reducers/appReducer'
import balanceReducer from './reducers/balanceReducer'
import accountReducer from './reducers/accountReducer'
import withdrawReducer from './reducers/withdrawReducer'
import luckydrawReducer from './reducers/luckydrawReducer'
import countDownReducer from './reducers/countDownReducer'
import cartReducer from './reducers/cartReducer'
import productReducer from './reducers/productReducer'

export default {
	authReducer,
	appReducer,
	balanceReducer,
	accountReducer,
	withdrawReducer,
	countDownReducer,
	luckydrawReducer,
	cartReducer,
	productReducer,
};
