import React, {Component} from 'react';
import { Route } from 'react-router-dom';

class RouteItem extends Component {
	constructor(props){super(props);}
	componentDidMount(){}
	render() {
		const { url, style , item, extraRoute, ...rest} = this.props;
		const { items, state, pathname, component} = item;
		return (
			<>
				{
					(Array.isArray(items) && items.length) ?
						items.map((sidebarItem, index) => (
						  <RouteItem key={`route-${index}`} item={sidebarItem}/>)
						)
					:
					<Route
						history={this.props.history}
						exact={true}
						key={state.nav_key}
						path={pathname}
						component={component}
						{...rest}
					></Route>
				}
			</>
		);
	}
}

export default RouteItem;
