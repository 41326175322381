import React, {useEffect} from "react"

import TabBar from "../components/TabBar"

function NotFound(props) {
	return (
		<div className="pb70 ">
				<div className="main-wrapper">
				<p className="text-center mt-4" style={{margin: "0px"}}>Oops</p>
				<p className="text-center" style={{margin: "0px"}}>Halaman Tidak Ditemukan</p>
				</div>
			<TabBar />
		</div>)
}

export default NotFound