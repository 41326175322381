import actions from '../actions/luckydrawAction';
const initState = {  
	loading : false,
	payload: {
		data : [],
		label : "Lucky Draw",
	}
};

export default function rootReducer(state = initState, action) {
	const { payload =[] } = action;
	switch (action.type) {
        case actions.GET_LD_COMPLETE:
		  	return {payload : payload, loading: false }
        case actions.GET_LD:
		  	return {...state ,loading : true }
		default:
			return state
	}
}