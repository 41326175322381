import { Button } from 'antd';
import React from 'react'

const ButtonGradient= ({
    style = {},
    label,
    onLoading,
    size,
    onDisabled,
    onclick,
    ...props
}) =>{
    const defaultSyle = {
        height:"42px",
        background: `linear-gradient(180deg, #1578A0 0%, #15929E 100%)`,
        borderRadius:"8px",
        textAlign:"center",
        marginTop:"10px",
        border: "none"
    };
    
    return(
        <Button
        className='gradient-button'
        block
        loading={onLoading}
        type="primary"
        disabled={onDisabled ? true : false} 
        size={size} 
        htmlType="submit"
        onClick={onclick}
        style={{...defaultSyle, ...style}}
        {...props}
        >
            {label}
        </Button>
    );
}

export default ButtonGradient;