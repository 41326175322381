import React from 'react';
// import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import {Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
// import {Route, NavLink,Link, BrowserRouter as Router , Switch, Redirect, useHistory} from 'react-router-dom'

import MainLayout from './pages/layouts/MainLayout';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import ScrollMemory from 'react-router-scroll-memory';
// import asyncComponent from './helpers/AsyncFunc';

import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (

  <Route
    {...rest}
    render={(props) => {
	    	const pathname = props.location.pathname
	    	if(pathname.match("/forgot-password")) {
	    		return (<>
	    			<PublicRoute
	    				exact
	        	  		history={props.history}
	        	  		path={"/forgot-password"}
	        	  		isLoggedIn={isLoggedIn}
	        	  		component={asyncComponent(() => import('./pages/auth/ForgotPasswordRequest'))}
	        		></PublicRoute>
	        		<PublicRoute
	    				exact
	        	  		history={props.history}
	        	  		path={"/forgot-password/:id/verify/:token"}
	        	  		isLoggedIn={isLoggedIn}
	        	  		component={asyncComponent(() => import('./pages/auth/ForgotPasswordOtp'))}
	        		></PublicRoute>
	        		</>)
	    	} else {
	    		if(isLoggedIn) {
	    			return <Component {...props} />
	    		} else {
	    			return <Redirect to="/login" />
	    		}
	    	}
    	}
    }
  />
)

const PublicRoute = ({ component: Component,isLoggedIn, ...rest }) => {
    return(
        <Route
          {...rest}
            render={props => isLoggedIn ? <Redirect to="/" /> : (<Component {...props} />)}
        />
  )
};
const MainRoute = ({ history, isLoggedIn }) => {
    return (
        <div>
        {/* <Router onUpdate={() => window.scrollTo(0, 0)} history={history}> */}
        <Router history={history}>
        	{/* <ScrollMemory /> */}
        	<PublicRoute
        	  history={history}
        	  path={"/login"}
        	  isLoggedIn={isLoggedIn}
        	  component={asyncComponent(() => import('./pages/auth/Login'))}
        	></PublicRoute>
            <RestrictedRoute
              history={history}
              path={"/"}
              isLoggedIn={isLoggedIn}
              component={MainLayout}
            ></RestrictedRoute>
        </Router>
        </div>
  );
};

export default connect(state => ({
  isLoggedIn: state.authReducer.isLoggedIn !== false,
}))(MainRoute);
