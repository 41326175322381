import React, {Component} from 'react';
import { Route , Redirect} from 'react-router-dom';
import RouteItem from './RouteItem';
import {connect} from 'react-redux';
class RouteMaps extends Component {
	constructor(props){
		super(props);

	}
	componentDidMount(){
		
	}
	
	render() {
		const { url, style , items, history,extraRoute,  ...rest} = this.props;
		const {isLoggedIn} =this.props;
		return (
			<div>
				{
					items.map((sidebarItem, index) => (
					  <RouteItem history={history}  key={sidebarItem.name} item={sidebarItem} {...rest}  />
					))
				}

				{extraRoute.map((item, index) =>(
					<Route
						history={this.props.history}
						exact={true}
						key={"invisibleRoute-"+item.name}
						path={item.pathname}
						component={
							isLoggedIn ? 
							item.component : 
							<Redirect
					          to={{
					          	pathname: '/login',
					            state: { from: this.props.location },
					          }}
		        			/>}
						{...rest}/>
					))
				}

				
			</div>
		
		);
	}
}

// export default TestRoute;
export default connect(
  state => ({
    // isLoggedIn : (state.AuthReducer.isLoggedIn) ? state.AuthReducer.isLoggedIn : false,
    isLoggedIn : true,
  }),
  {}
)(RouteMaps);