import { Card } from "antd";
import React from "react";

function LuckyDrawListShimmer ({dataLength = 2, ...props }) 
{

    function getShimmer() {
        var ab = [];
        for(var i = 0; i <= 4; i++ )
        {
            ab.push(
                <div className="d-flex" key={`_ldr-${i}`}>
                    <div  className="mr-2 mb-3" style={{flex: 2}}>
                        <div className="shine" style={{ height: 100, width: "100%"}}></div>
                        <div className="shine" style={{ height: 15, width: "100%"}}></div>
                    </div>
                </div>
                )
        }
        return ab 
    }
    return (
        <>
        <div className="shine mt-4 mb-2" style={{ height: 24, width: 100}}></div>
        {getShimmer()}
        </>
    )
}

export default LuckyDrawListShimmer