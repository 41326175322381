import {addMinutes} from './globalHelper';
import { store } from './../redux/store';
import authAction from '../redux/actions/authAction';
import Cookies from 'universal-cookie';
import env from "../environment"
const axios = require('axios').default;
const TAG = 'AuthHelper';
const cookies = new Cookies();
const cookies_name = "access_token";
const accessTokenName = "client-key"
const refreshTokenAtName = "exchange-time"
const expiredTokenAtName = "__expired"
const refreshTokenName = "_exchange-key"
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

class AuthHelper {

	constructor() {
		this.refresh_token = false;
		
	}

	_localStorage() {

		try{
			return {
				access_token : cookies.get(accessTokenName),
				refresh_at: cookies.get(refreshTokenAtName),
				expires_in: cookies.get(expiredTokenAtName),
				refresh_token: cookies.get(refreshTokenName)
			};
		}catch(e){
			return false;
		}
	}

	async token(){
			
		try{
			var _localStorage 	= this._localStorage();
			var refresh_at 		= 0;
			let current_time 	= new Date().getTime();
			var formData 		= new FormData();
			var fetchApi 		= null;
			var refresh_token 	= null;
			
			if(_localStorage){
				refresh_at 		= await this.refreshAt();
				refresh_token 	= await this.refreshToken();
				if(refresh_at > 0 && refresh_at < current_time && this.refresh_token == false && refresh_token != null){
				
				/*console.log(TAG, {  
					refresh_at : refresh_at, current_time: current_time,
					refresh_token: this.refresh_token,
					refresh_AccessToken: refresh_token,
					need_refresh : ((refresh_at > 0) && (refresh_at < current_time ))
				});*/

					this.refresh_token = true;
					formData.append('grant_type', 'refresh_token');
					formData.append('refresh_token', refresh_token);
					formData.append('client_id', env.apiClientId);
					formData.append('client_secret', env.apiSecret);
					formData.append('scope', '');
			    	fetchApi = await axios({
			    		crossdomain: true,
			    	    method: 'post',
			    	    url: "api/v1/oauth/token",
			    	    data: formData,
			    	    headers: {'Content-Type': 'multipart/form-data' }
				    }).then(response => {
				    	return response;
				    }).catch(function (response) {
				        console.log(TAG, response);
				    });	
					if (fetchApi.data.access_token) {
					    var payload = {
					    	access_token : fetchApi.data.access_token, 
					    	expires_in : fetchApi.data.expires_in, 
					    	refresh_token : fetchApi.data.refresh_token
					    };
					    this.setToken(payload);
					} else {
						store.dispatch(authAction.noAuth());
					}
					this.refresh_token = false;

				}else{
					return _localStorage.access_token;
				}

				_localStorage = this._localStorage();
				return _localStorage.access_token;	
			}
			store.dispatch(authAction.noAuth());
			return false;
		}catch(e){
			return false;
		}
	}

	refreshToken() {
		try{
			var _localStorage = this._localStorage();
			if(_localStorage){
				return _localStorage.refresh_token;
			}
			return null;
		}catch(e){
			return null;
		}
	}
	
	refreshAt(){
		try{
			var _localStorage = this._localStorage();
			if(_localStorage){
				return _localStorage.refresh_at;
			}
			return 0;
		}catch(e){
			return 0;
		}
	}
	expired(){
		try{
			var localStorage = this._localStorage();
			if(this._localStorage()){
				return localStorage.expired;
			}
			return 0;
		}catch(e){
			return 0;
		}
	}

	

	isLoggedIn() {
		try { 
			return cookies.get(accessTokenName);
		}catch(e) {
			return false;
		}
	}

	setToken(payload) {
		return new Promise((resolve, reject,) => {
			if(typeof payload === 'undefined') {
				return reject({message : "Error"});
			}
			try {
				const { access_token, expires_in, refresh_token } = payload;
				// var tokenExpireInHours = (parseInt(expires_in)) / 60 / 60;
				var tokenExpireInMinutes = (parseInt(expires_in)) / 60;
				// var token_expires_in_minutes = (parseInt(expires_in) / 60) - 5;
				var token_expires_in_minutes = (parseInt(expires_in) / 60) - 3;
				if (token_expires_in_minutes <= 0) token_expires_in_minutes = 0;
				var refresh_at = addMinutes(new Date(), token_expires_in_minutes);
				cookies.set(accessTokenName, access_token, {path : "/", expires: addMinutes(new Date(),tokenExpireInMinutes)});
				refresh_at = refresh_at.getTime();
				cookies.set(expiredTokenAtName, expires_in, {path : "/", expires: addMinutes(new Date(), tokenExpireInMinutes)});
				cookies.set(refreshTokenAtName, refresh_at, {path : "/", expires: addMinutes( new Date(),10)});
				cookies.set(refreshTokenName, refresh_token, {path : "/", expires: addMinutes(new Date(), 10)});
				resolve();
			} catch(e){
				return reject(e.message);
			}
		});

	}

	removeToken(){
		return new Promise((resolve, reject) => {
			try{
				cookies.remove(cookies_name);
				localStorage.removeItem(accessTokenName);
				localStorage.removeItem(refreshTokenName);
				localStorage.removeItem(refreshTokenAtName);
				localStorage.removeItem(expiredTokenAtName);
				store.dispatch(authAction.noAuth());
			}catch(e){}
		})
	}

	checkAuthorization () {

	}
}


export default (new AuthHelper());
