import actions from '../actions/authAction';

import AuthHelpers from '../../helpers/authHelper';
let isLoggedIn = (AuthHelpers.isLoggedIn()) ? true : false;
const initState = {  
	isLoggedIn : isLoggedIn,
	isFailed: false
};

export default function authReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
		
		  return {...state, isLoggedIn: true};
		
		case actions.LOGIN_FAILED:
		
		  return {...state, isFailed: true};
		case actions.CLEAR_LOGIN_FAILED:
		
		  return {...state, isFailed: false};
		
		case actions.LOGOUT:
		
		  return {...state, isLoggedIn: false};
		
		default:
			return state;
	}
}