export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export const currencyFormater = (value) => (`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/[^0-9,.]+/g, '-'))
/*export const currencyFormater = (value) => {
	
}*/
export const unitFormater = (value, unit) => (`$ ${value} `.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
export const currencyParser = (value) => (value.replace(/RP\s?|(,*)/g, ''))
export const unitParser = (value, unit) => (value.replace(`/${unit}\s?|(,*)/g`, ''))
export const currencyStyle = (width) => ({width : width, paddingRight : "1.2em"})