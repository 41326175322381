import React, { useState, useEffect, useRef, createRef, useReducer } from "react"

export function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

    return operatingSystem;
}
const ButtonBox = ({ children }) => {
    return <div className="buttonBox">{children}</div>;
};
const Button = ({ className, value, onClick }) => {
    return (
        <button className={className} onClick={onClick}>
            {value == "<" ? <i className="fas fa-backspace"></i> : value}
        </button>
    );
};

function Wrapper({ children }) {
    return <div className="wrapper">{children}</div>
}
const btnValues = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [ "Kembali", 0, , "<"],
];
const initialState = [];

function reducer(state, action) {
    switch (action.type) {
        case 'fill':
            return state.concat(action.value);
        case 'unfill':
            var a = { prev: state };
            const b = state;
            return action.value;
        default:
            throw new Error();
    }
}
function PinValueContainer({ data, ...props }) {
    const arr = new Array(6).fill(0)


    const elRefs = useRef([]);
    if (elRefs.current.length !== 6) {
        elRefs.current = Array(6).fill().map((_, i) => elRefs.current[i] || createRef());
    }
    useEffect((prev) => {
        if (data.value.length > 0) {
            if (data.action == "add") {
                elRefs.current[data.value.length - 1].current.classList.add("filled")
            } if (data.value.length == 6) {
                props.onComplete(data.value.join(""))
            } else {
                elRefs.current[data.value.length > 0 ? data.value.length : 0].current.classList.remove("filled")
            }
        } else {
            elRefs.current[0].current.classList.remove("filled")
        }
    }, [data.value])
    return <>
        <div key={`pvalb`} className="pin-value-container">
            {arr.map((el, i) => {
                return (
                    <input 
                    pattern="[+-]?\d+(?:[.,]\d+)?" key={`pval-${i}`} className="pin-value-item" ref={elRefs.current[i]} />)
            }
            )}

        </div>
    </>
}

function CustomPin({ children, ...props }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [tapAction, setTapAction] = useState(null)
    const handleTap = (value) => {
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].indexOf(value) >= 0) {
            if (state.length < 6) {
                setTapAction("add")
                dispatch({ type: 'fill', value: value })
            }
        } else if (value == '<') {
            var b = [];
            var i = 0;
            state.forEach(element => {
                if (i < state.length - 1) {
                    b.push(element)
                }
                i++;
            });
            setTapAction("remove")
            dispatch({ type: 'unfill', value: b })
        }else if(value == "Kembali") {
            props.onBack()
        }
    }
    const allowedKey = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    const backSpaceKeyCode = [8];
    const handleKeyDown = async (event) => {
        const { key, keyCode } = event;
        const keyInt = parseInt(key)
        if (allowedKey.indexOf(keyInt) >= 0) {
            handleTap(keyInt)
        } else if (backSpaceKeyCode.indexOf(keyCode) >= 0) {
            handleTap("<")
        }

    }
    useEffect(() => {
        // alert(getOperatingSystem(window))
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [state])
    function onComplete(e) {
        props.onFinish(e)
    }
    return (<Wrapper>
        <PinValueContainer key="pvcontaienr" data={{ value: state, action: tapAction }} onComplete={onComplete} />
        <p style={{
            textAlign: "center",
            backgroundColor: "none !important",
            border: "none",
            marginTop: 20
        }} onClick={props.onBack} value="Kembali" >Kembali</p>
        {/* {
            btnValues.map((btn, i) => {
                return (
                    <ButtonBox key={i}>
                        {btn.map((el, a) => {
                            return <Button
                                key={a}
                                className={el === "Kembali" ? "numb-button back" : "numb-button"}
                                value={el}
                                onClick={() => {

                                    handleTap(el)
                                }}
                            />
                        })}
                    </ButtonBox>
                );
            })
        } */}
    </Wrapper>)
}

export default CustomPin;