import actions from "../actions/cartAction";

const initState = {
    loading: false,
    numberCart:0,
    cartTotal: 0,
    currentPage:0,
    totalPage:0,
    totalRecords:0,
    Carts:[],
    Products:[],
    session_id : null,
    get_cart_complete: true,
    warning : null
}

export default function cartReducer(state = initState, action) {
    const { payload, countPayload, sessionID = null , cartTotal = 0 , warning = null} = action;
    
    switch (action.type) {
        case actions.GET_ALL_PRODUCT:
            return{ 
                ...state, 
                loading: true
            }
        case actions.GET_PRODUCT_COMPLETE:
          
            return {
                ...state,
                loading: false, 
                Products : payload.items,
                currentPage :payload.currentPage,
                totalPage :payload.totalPage,
                totalRecords :payload.totalRecords,
               
            }

        case actions.GET_SEARCH_PRODUCT:
            
            return {
                ...state,
                loading:true,
                // Products : payload.items,
                // currentPage :payload.currentPage,
                // totalPage :payload.totalPage,
                // totalRecords :payload.totalRecords,
            }
        case actions.GET_CART:
            return {
                ...state,
                
                get_cart_complete :false,
            }
        case actions.GET_CART_COMPLETE:
            return {
                ...state,
                Carts: payload,
                cartTotal: cartTotal,
                session_id: sessionID,
                numberCart : payload.length,
                get_cart_complete: true,
                warning :warning,

            }
        case actions.GET_NUMBER_CART:
            return{ 
                ...state,
                numberCart : countPayload
            }
        default:
            return state;
    }
}