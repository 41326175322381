import React, { useState, useEffect } from "react";
import { Progress, Row, Col, Avatar, Card, Typography, Badge, notification } from "antd";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";

import appAction from "../redux/actions/appAction";
import { formatMoney } from "../helpers/CurrencyFormat";
import balanceAction from "../redux/actions/balanceAction";
import accountAction from "../redux/actions/accountAction";
import luckydrawAction from "../redux/actions/luckydrawAction";
import TabBar from "../components/TabBar";
import ReactGA from "react-ga";
import AuthLib from "../helpers/AuthLib";
import ApiCaller from "../helpers/ApiCaller";
import LuckyDrawListShimmer from "../components/shimmer/LuckyDrawListShimmer";
import FiturShimmer from "../components/shimmer/FiturShimmer";
import { fetchToken, onMessageListener } from "../helpers/firebase";

// const {formatMoney} = Currency
const { getInfo } = accountAction;
const { getLd } = luckydrawAction
const { Meta } = Card;
const { switchHeader, hideHeader } = appAction;
const { getBalance } = balanceAction;
const { Text } = Typography;

ReactGA.initialize("UA-146897934-1",{ standardImplementation: true });
const Dashboard = ({luckydrawReducer, ...props}) => {
  const location = useLocation();
  const { total_days, cycle_date, balance, total_dtd, cycle_start } =
    props.balanceReducer;
  const [isTokenFound, setTokenFound] = useState(false);
  const { accountReducer } = props;
  const { accountInfo = {} } = accountReducer;
  const { name = "" } = accountInfo;
  const [loadingFeature, setLoadingFeature] = useState(true)
  const [featureLabel , setFeatureLabel] = useState("")
  const [dataFeature, setDataFeature] = useState([]);
  const [show,setShow] =useState(false);


  onMessageListener().then(payload => {
    notification.open({
    message: payload.notification.title,
    description: payload.notification.body,
    onClick: () => {
      console.log('Notification Clicked!');
    },
    });
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  useEffect(() => {
    // props.switchHeader({headerType: "image", headerText: null})
    props.hideHeader();
    document
      .getElementsByClassName("site-layout-background")[0]
      .classList.add("with-background");
    props.getBalance({});
    props.getInfo();
    props.getLd()
    document.title = "PaidbaQ | Home";
    getMenuFeature();
    ReactGA.pageview(location.pathname + location.search);
    fetchToken(setTokenFound);
    return () => {
      try {
        document
          .getElementsByClassName("site-layout-background")[0]
          .classList.remove("with-background");
      } catch (e) {}
    };
    
  }, []);
  async function getMenuFeature() {
    await ApiCaller({
      url: "/v1/features/list",
      method: "GET",
      crossdomain: true,
    })
      .then((res) => {
        const { data = {} } = res;
        const { payload = {}} = data
        const { features : dataFeature} = payload
        const featureLabelStr = payload ? payload.label : ""
        setDataFeature(dataFeature);
        setFeatureLabel(featureLabelStr)
      })
      .catch((error) => {});
      setLoadingFeature(false)
  }
  // console.log(dataFeature);
  const pieValue = () => {
    var percentage = 0;
    if (total_dtd > 0) {
      percentage = (total_dtd / total_days) * 100;
    }
    return `${percentage}`;
  };
  function logout() {
    AuthLib.removeToken();
  }

  const title = (
    <span className="font-content">
      Hello, <span className="font-content-medium">{name}</span>
    </span>
  );

  const sliderSetting = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
  }
  const additinalIcon = (item) => {
    if(item.additional_icon !="") {

      return <img className="feature-additional-icon" src={item.additional_icon} />
    } else{
      return <></>
    }
  }
  const renderLuckydaw = () => {
    const { payload : luckydrawData} = luckydrawReducer
    const luckydrawLabel = luckydrawData ? luckydrawData.label : "Lucky Draw"
    try{
      if(luckydrawData.data.length > 0) {
        var x = luckydrawData.data.map((item, idx) => {
          return (
              <div className="carousel-luckydraw vertical" key={`lcd-${idx}`}>
                <Link to={`/luckydraw/${item.id}`}>
                  {/* <img src={item.image} /> */}
                  <img src={item.image} alt="" style={{width: "100%", background: "white"}} />
                  <p className="font-title mt-3 mb-0">{item.name}</p>
                  <p className="font-SubItem-12 text-gray mt-1" style={{fontWeight: 400}}>{item.subtitle}</p>
                </Link>
                
              </div>
            )
          })
          return (
            <>
            {luckydrawReducer.loading == true ?
              <LuckyDrawListShimmer /> :
              <Row
                  style={{
                    marginTop: "20px",
                    marginBottom : "60px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                ><Col className="mb-3 mt-2">
              <Text className="font-title">{luckydrawLabel} </Text>
              </Col>
              <Col>
              <div>
                {x}
              </div>
              {/* <Slider {...sliderSetting}>
                {x}
              </Slider> */}
                  
                  </Col></Row>
          
          
        }
            
            
        </>
          )

      }
    } catch(e) {
     
      return ""
    }
    
    
  }
  return (
    <div className="pb70 ">
      <div className="main-wrapper px-3 py-3" style={style}>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
              <img
                src="./images/Final-logo-paidbaq-07.png"
                style={{ width: "100%" }}
                alt=""
              />
          </Col>
          <Col span={8}>
            <Row
              style={{ paddingRight: 0, top: "2.4px" }}
            >
              <Link to="/notification">
                <Badge dot={show}>
                  <img src="./images/Notification.png" alt="" style={{ paddingLeft:"59px" }}/>
                </Badge>
              </Link>
              <a  onClick={() => logout()}>
                <img src="./images/exit.png" alt="" style={{marginLeft:"15px"}}/>
              </a>
            </Row>
          </Col>
        </Row>
        <>
            { props.balanceReducer.loading  ?
              <div className="shine" style={{ height: 200, width: "100%", marginTop: 60, marginBottom : 12}}></div>
              :
              (<Card
                className="border-radius mb-1"
                style={{
                  width: "100%",
                  top: "49px",
                  boxShadow: `0px 0px 6px rgba(0, 0, 0, 0.1)`,
                }}
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Meta
                  avatar={
                    <Avatar
                      size={40}
                      src={accountInfo.profile_image}
                    />
                  }
                  title={title}
                  description=""
                />
                <hr
                  style={{
                    marginTop: 16,
                    marginBottom: 12,
                    borderBottom: "1px solid #f9f9f9",
                    marginLeft: "-24px",
                    marginRight: "-24px",
                  }}
                />
                <label
                  className=" font-subtext"
                  style={{ color: "#636D72" }}
                >
                  Saldo Kamu
                </label>
                <label
                  className="text-primary font-balance"
                  id="balance-static"
                >
                  Rp {formatMoney(balance, 0)}
                </label>
                <div className="single-chart">
                  <Progress type="line" percent={pieValue()} showInfo={false} 
                    strokeColor={{
                      "0%": "#1578A0",
                      "100%": "#15929E",
                    }}
                    />
                  <Row justify="space-between">
                    <Col span={16}>
                      <label
                        className="font-subtext-medium"
                        style={{ color: "#636D72" }}
                      >
                        Siklus Baru Tanggal <span style={{color: "black", fontWeight: 600}}>{cycle_start} </span>
                        <span
                          id="cycle-date-value"
                          className="font-subtext"
                          style={{ color: "#2C2C2C" }}
                        >
                          {cycle_date}
                        </span>
                      </label>
                    </Col>
                    <Col span={4} className="text-right">
                      <h3 className="font-subtext-medium text-primary">
                        {total_dtd} Hari
                      </h3>
                    </Col>
                  </Row>
                </div>
              </Card>)
              
            }
            

            { 
            loadingFeature ?
            <FiturShimmer /> :
              (<Row
                style={{
                  paddingTop: "70px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                 
                <Col className="mb-2">
                  <Text className="font-title">{featureLabel}</Text>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    marginLeft: "-5px",
                    marginRight: "-5px",
                  }}
                >
                  {dataFeature.map((item, idx) => {
                    var url = "";
                    var buttons = "";
                    var images = "";
                    var filterOpacity  = {}
                    var inactiveStyle = {}
                    if(item.state == "inactive") {
                      filterOpacity = {
                        opacity: .6
                      }
                      inactiveStyle = {
                        background: "rgba(0,0,0,.06)"
                      }
                    }
                    switch (item.id) {
                      case "07eb7be7-dc2d-4145-91ba-52050cd2cae4":
                        url = "/withdraw";
                        buttons = button1;
                        images = img1;
                        break;
                      case "86b3ac95-bfce-441b-8666-f0d89b671e71":
                        url = "/loans";
                        buttons = button2;
                        images = img2;
                        break;
                      case "72309b54-89e5-41f1-9d10-86b42ca0b32b":
                        url = "/absence";
                        buttons = button3;
                        images = img3;
                        break;
                      case "a9b76dae-b65e-463a-b042-25d31c5d7f87":
                        url = "/bills";
                        buttons = button4;
                        images = img4;
                        break;
                      case "b5fb7271-c118-4659-85f6-7b4e2ae613c8":
                        url = "/grocery";
                        buttons = button5;
                        images = img5;
                        break;
  
                      default:
                        break;
                    }
                    var xxb = 0;
                    if(item.state == "inactive"){
                      xxb = 0;
                    }
                    return (
                      
                      <Link
  
                        to={url}
                        style={{
                          pointerEvents: item.state === "inactive" ? 'none' : '',
                          width: "18.5%",
                          paddingTop: "16%",
                          position:"relative",
                          marginLeft: "5px",
                          marginRight: "5px",
                          display: "flex",
                          flexDirection: "column",
                          // border: "1px solid red"
                        }}
                        className="text-center"
                        hidden={item.state === "hidden" ? "hidden" : ""}
                        key={idx}
                      >
                        
                          {/* <img src={item.icon} alt="" style={{width: "100%"}} /> */}
                          {additinalIcon(item)}
                          <img 
                            src={item.icon} 
                            alt="" style={{
                              position: "absolute",
                              top: xxb,
                              width: "100%", 
                              borderRadius: "8px",
                              // boxShadow: `0px 0px 5px 1px rgba(0, 0, 0, 0.1)`, 
                              // border: "2px solid white"
                            }} 
                            />
                        
                        <label className="font-subtext-medium mt-2" style={{fontSize: ".7rem", fontWeight: 500}}>
                          {item.label}
                        </label>
                      </Link>
                    );
                  })}
                </Col>
              </Row>)
            }
            
            

            
            {renderLuckydaw()}
             
          </>
      </div>
      <TabBar />
    </div>
  );
};
const style = {
  background: `url('/images/header_dasboard.png')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  minHeight:"49rem"
};
const img1 = {
  position: "absolute",
  top: "2%",
  left: "1%",
};
const img2 = {
  position: "absolute",
  top: "2%",
  left: "22%",
};
const img3 = {
  position: "absolute",
  top: "2%",
  left: "43%",
};
const img4 = {
  position: "absolute",
  top: "2%",
  left: "64%",
};
const img5 = {
  position: "absolute",
  top: "2%",
  left: "84%",
};
const icon = {
  position: "relative",
  padding: 0,
  cursor: "pointer",
  // opacity: 0.4,
  borderRadius: "8px",
  boxShadow: `0px 0px 5px 1px rgba(0, 0, 0, 0.1)`,
  display: "flex",
  justifyContent: "center",
  border: "2px solid white",
  width: "100%",
  alignItems: "center",
  height: "auto"
};
const button1 = {
  flex: 1,
  background: `linear-gradient(136.31deg, #97DA7B -37.47%, rgba(151, 218, 123, 0) 83.89%)`,
  ...icon,
};
const button2 = {
  flex: 1,
  background: `linear-gradient(143.29deg, rgba(218, 56, 125, 0.7) -10.74%, rgba(223, 107, 87, 0) 79.98%)`,
  ...icon,
};
const button3 = {
  flex: 1,
  background: `linear-gradient(141.82deg, rgba(74, 180, 251, 0.67) 2.66%, rgba(74, 180, 251, 0) 83.1%)`,
  ...icon,
};
const button4 = {
  flex: 1,
  background: `linear-gradient(136.79deg, #E0A32B -6.77%, rgba(224, 163, 43, 0) 79.03%)`,
  ...icon,
};
const button5 = {
  flex: 1,
  
  background: `linear-gradient(132.6deg, rgba(247, 213, 34, 0.67) -3.61%, rgba(247, 213, 34, 0) 77.21%)`,
  ...icon,
};
export default connect(
  (state) => ({
    appReducer: state.appReducer,
    balanceReducer: state.balanceReducer,
    accountReducer: state.accountReducer,
    luckydrawReducer: state.luckydrawReducer,
  }),
  { switchHeader, hideHeader, getBalance, getInfo, getLd }
)(Dashboard);
