import React, { useState } from "react";

import { useHistory } from "react-router";
import { Form, Row, Col, Drawer, Input, Space, Button } from "antd";
import PinFieldComponent from "../../components/PinFieldComponent";
import CustomPin from "./CustomPin";
import ButtonGradient from "@Components/ButtonGradient"

const LoginActivation = (props) => {
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const history = useHistory();
  const onFinish = (values) => {
    // console.log(values.password);
    // history.push("/");

    props.onSubmit(values.password);
  };

  const onFinishFailed = (errorInfo) => {};

  function onPrevious() {
    props.onPrevious();
  }
  function handleComplete(e) {
    // setLoadingButton(true)
    // props.onSubmit(e.value)
  }

  function handleChange(e) {
    // props.clearLoginFailed()
  }
  function handleClear(e) {
    setClearInput(false);
  }

  return (
    <>
      <Drawer
        placement="bottom"
        width={420}
        height="auto"
        className="custom-drawer"
        visible={props.actVisible}
        closable={false}
        closeIcon={false}
      >
        <Row
          style={{ display: "flex", flexDirection: "column" }}
          gutter="12"
        >
          <Col className="text-center">
            <label className="font-title text-center">{props.title}</label>
          </Col>
          <Col>
            {/* <CustomPin onFinish={onFinish} onBack={onPrevious} /> */}
            <Form size="large"
              autoComplete="off"
              scrollToFirstError={true}
              form={form}
              name="activation_form"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed} >

              <Form.Item
                  
                  label="PIN"
                  name="password"
                  rules={[
                    { required: true, message: 'PIN tidak boleh kosong' },
                    { min: 6, message: 'Pin harus 6 angka' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          var pattern = /^\d+$/;
                      if ( !pattern.test(value) && value.length == 6) {
                        return Promise.reject(new Error('PIN harus berupa angka'));
                      }
                      return Promise.resolve();
                    },
                  }),
                  ]} >
              
              <Input 
                pattern="[0-9]*" inputMode="numeric" 
                className="input-default input-gray text-center input-xl" 
                autoFocus={true} 
                maxLength={6}
                type="password" 
                placeholder="Masukkan PIN baru"

                />
              </Form.Item>
              <Form.Item
                  
                  label="Konfirmasi PIN"
                  name="confirm_password"
                  rules={[
                    { required: true, message: 'Konfirmasi pin harus diisi' },
                    { min: 6, message: 'Pin harus 6 angka' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          var pattern = /^\d+$/;
                      if ( !pattern.test(value) && value.length == 6) {
                        return Promise.reject(new Error('PIN harus berupa angka'));
                      }
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      if(value.length == 6 &&  getFieldValue('password') !== value) {

                        return Promise.reject(new Error('Pin tidak sesuai'));
                      }
                    },
                  }),
                  ]} >
              
              <Input
                pattern="[0-9]*" inputMode="numeric" 
                className="input-default input-gray text-center input-xl" 
                type="password" 
                maxLength={6}
                placeholder="Konfirmasi PIN"


                />
              </Form.Item>
              <Form.Item >
              <Row  gutter={24}>
                <Col span={12}>
                    <Button 
                        onClick={onPrevious}
                        block
                        type="text"
                        size="large" 
                        className="btn-app"
                        htmlType="button">
                        <i className="fas fa-caret-left fa-fw" /> Sebelumnya
                    </Button>
                </Col>
                <Col span={12}>
                <ButtonGradient 
                    icon={<i className="fas fa-sign-in-alt fa-fw" style={{marginRight: 10}}></i>}
                    style={{marginTop: 0}}
                    label=" Selanjutnya" 
                    size="large" 
                    onDisabled={false} />
                </Col>
              </Row>
              
            </Form.Item>
            </Form>
          </Col>
        </Row>
        {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
        {/* </div> */}
      </Drawer>
    </>
  );
};

export default LoginActivation;
