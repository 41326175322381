import actions from '../actions/balanceAction';
const initState = {  
	loading: false,
	accessable_salary : 0,
	balance : 0,
	currency : "Rp ",
	current_date : null,
	cycle_end : null,
	cycle_start : null,
	deferred : 0,
	eo_withdrawal : null,
	invoicing : false,
	prorate : 0,
	start_at : null,
	total_days : 0,
	total_dtd : 0,
	unpaid_invoice : 0,
	withdrawal_access : false,
	alert: ""
};

export default function rootReducer(state = initState, action) {
	const { payload } = action;
	switch (action.type) {
		case actions.GET_BALANCE:
			return {...state, loading: true}
		case actions.GET_BALANCE_COMPLETE:
		 	return {...state,loading: false, ...payload}
		default:
			return state
	}
}